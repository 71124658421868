import React from "react";

const LaunchpadLogo = () => (
    <svg version="1.1"  x="0px" y="0px" viewBox="0 50 800 550" enable-background="new 0 0 800 550" height="400px"> 
    <g id="Layer_7">
    <g>
        <g>
            <path fill="#D1D3D4" d="M345,361.8c5.4,1.9-0.8-0.6-2-1c-5.4-1.9-10.9,4-9.2,9.2c3.4,11,23.6,15.8,28.2,3.2
                c2.5-6.8-2.7-13.1-6.8-18c-4.9-5.9-10.6-11.8-13.7-19c-3.9,3-7.9,6.1-11.8,9.1c4.9,5.8,12.8,11.2,15.1,18.7
                c4.6-1.9,9.1-3.9,13.7-5.8c-3.5-6.6-8.8-11.9-13-18c-4.3-6.3-7.9-13.1-11-20c-1.3-3-5.5-4.3-8.5-3.4c-4.3,1.2-5.8,5.2-5.2,9.2
                c0.7,4.8,4.4,8.3,8.4,10.5c5.5,3,13.2-1.9,11-8.5c-1.8-5.4-5.5-9.5-8.1-14.4c-3.6-6.6-5.6-13.7-7.8-20.9c-2-6.6-3.7-13.3-5-20.1
                c-1.2-5.9-1.6-11.8-4.2-17.3c-2.6-5.4-9.1-7.5-14.6-8.1c-6.1-0.7-12.1,4.2-17.4,6.5c-5.1,2.3-9.3,6.3-14.4,8.6
                c-2.4,1.1-5.4,3.1-6.6,5.6c0.4-0.3,0.8-0.5,1.2-0.8c-0.9,0.5-1.7,1.2-2.2,2.1c-0.8,1.1-1.4,2.4-2.3,3.4
                c-5.1,6.2,1.5,16.8,9.1,11.8c7-4.6,12.2-11.3,19.5-15.3c2-1.1,4.3,1.1,5.8,2.3c3.1,2.4,6.1,4.5,9.9,5.8c0.7-4.9,1.3-9.8,2-14.7
                c-7.4,0.1-15.2,0.6-22,3.5c-3.7,1.6-6.6,3.8-9.5,6.6c-2.3,2.3-4,4.7-7.5,4.8c1.8,4.3,3.5,8.5,5.3,12.8c3.3-3.2,6.8-5.9,11.6-6
                c2.9-0.1,7.5,1.6,8.3,4.4c2,6.8-2.3,16-4.4,22.3c-3.1,9.2,11.3,13,14.5,4c2.7-7.5,3.7-15.4,4.7-23.3c0.9-6.8,1.7-13.4-5.9-16.5
                c-5.9-2.4-10.8,0.8-14,5.6c-1.4,2.1-2.3,4.7-3.2,7.1c-0.4,1.1-0.8,2.3-1.2,3.5c-0.2,0.5-1.2,3.4-0.2,0.9
                c-2.6,6.1-4.7,21.5,3.1,25.1c14.3,6.6,19.7-16.1,22.7-25.3c-4.6,0.6-9.1,1.2-13.7,1.8c4.8,6.5,6,15,12.2,20.5
                c3.9,3.5,11.3,2.5,12.5-3.3c1.7-8.1-3.4-13.1-11.2-13.5c-3.6-0.2-6,2.5-7.2,5.5c-3.7,9.3,3.1,19.2,7.8,26.8c4.3-2.5,8.6-5,13-7.6
                c-5.8-7.9-11.8-17.8-22.5-18.7c-4.3-0.4-7.2,3.7-7.5,7.5c-0.6,6.7,3.1,12.7,6.3,18.3c3.4,5.9,6.7,11.9,8,18.7
                c4.2-2.4,8.4-4.9,12.5-7.3c-4.7-4.1-6.2-9.1-9.3-14.3c-2.7-4.6-3.4-10.2-7.7-13.7c-4.1-3.4-11.1-2.6-12.5,3.3
                c-4.1,16.6,6.7,27.8,14.8,40.8c2.2-3.8,4.3-7.5,6.5-11.3c-3.6-0.1-7-0.3-10.6,0.7c-3.6,1-6.7,4.1-9.2,6.7
                c-6,6.4-6.1,13.9-2.7,21.6c2.7,6.1,8.9,9.9,13.1,14.9c4.6,5.4,9.4,8.8,16.8,8.4c8-0.4,14.2-4.9,19.7-10.3
                c6.1-5.9,9.9-12.6,6.2-21c-5.5-12.6-19.4-20.3-22.2-34.3c-1.7-8.4-14.9-6.3-14.7,2c0.2,12.3,7.6,48.9,27.1,39.1
                c8.4-4.2,5.6-17.5,3.7-24.5c-2.2-8.2-6.5-19-15.3-21.9c-3.9-1.3-8.2,1.4-9.2,5.2c-3.9,14.1,7.9,33.3,20.4,39.5
                c4.5,2.2,12.1-0.3,11.3-6.5c-1.2-9.3-5.1-17.3-9.4-25.5c-4.2-8-7-16.5-11.6-24.3c-4.3,2.5-8.6,5-13,7.6
                c5.8,8.6,11.6,17.2,17.8,25.5c1.8,2.4,3.7,4.8,5.8,7.1c0.8,0.9,1.7,1.9,2.4,2.9c1.1,1.6,0.7,2.3,1.2,0.7
                c-3.1,9.2,11.4,13.1,14.5,4c2.7-7.9-4.8-15.2-9.5-20.5c-0.7-0.8-0.7-0.9-0.2-0.2c-1.1-1.5-2.3-3-3.4-4.5c-1.8-2.5-3.6-5-5.4-7.5
                c-3.5-4.9-6.8-10-10.2-15c-5.3-8-17.9-0.8-13,7.6c4,6.9,6.5,14.3,10.1,21.3c3.3,6.6,7.9,13.4,8.9,20.9c3.8-2.2,7.5-4.3,11.3-6.5
                c-6.6-3.2-15.6-15.1-13.6-22.5c-3.1,1.7-6.2,3.5-9.2,5.2c-0.8-0.2-1-0.6-0.4,0c0.5,0.6,0.6,0.7,0.2,0.2c0.5,0.7,0.9,1.4,1.4,2.1
                c1.3,2.3,2.3,4.7,3.1,7.2c0.8,2.3,1.6,5.8,1.8,7.5c0.2,1.3,0.2,2.7,0.1,4c0.1-0.9,0.1-0.9,0,0c-0.1,0.2-0.1,0.4-0.2,0.6
                c0.3-1.1,1-2,2-2.5c1.7-1.2,2.2-0.9,3.3-0.3c-0.7-0.3-0.4-0.2-1.1-1.2c-1.8-2.6-2.8-5.6-3.7-8.6c-1.7-5.5-2.9-10.9-3-16.1
                c-4.9,0.7-9.8,1.3-14.7,2c1.9,9.6,8,17.2,14.1,24.6c2.6,3.2,5.2,6.4,7.6,9.7c1.2,1.6,1.7,2.6,1.4,3.2c-1,2-3.8,3.9-5.5,5.4
                c-2.5,2.4-6.1,4.6-9.6,3.7c-0.7-0.2-2.1-2.2-2.5-2.8c-1.5-1.7-2.9-3.4-4.6-5c-2.6-2.5-7.4-5.8-7.6-9.8c-0.1-1.6,3.3-3.9,4.3-4.7
                c0.4-0.3,4.5,0.2,5.7,0.2c5.6,0.1,9.4-6.5,6.5-11.3c-5.3-8.6-16-18.2-13.2-29.2c-4.2,1.1-8.4,2.2-12.5,3.3
                c2.7,2.2,4.3,7.8,5.6,11c2.6,6.4,6.1,12.5,11.4,17c5.8,5,14,0.2,12.5-7.3c-1.3-6.7-4.3-12.9-7.8-18.8c-2.3-4-6.4-9.5-6-14.2
                c-2.5,2.5-5,5-7.5,7.5c0.9,0.1,3.8,3.8,4.7,4.8c1.8,2,3.3,4.3,4.9,6.5c5.6,7.6,18.1,0.9,13-7.6c-2.3-3.8-8.1-10.5-6.2-15.2
                c-2.4,1.8-4.8,3.7-7.2,5.5c1.3,0.1,0.6-0.6-2.3-1.8c-0.3-1.3-0.7-2.5-1-3.8c0,0,0.1,0.1,0.1,0.1c4.2-1.1,8.4-2.2,12.5-3.3
                c-4.9-4.4-5.9-12.2-9.8-17.5c-3.8-5.2-11.6-4.8-13.7,1.8c-1.2,3.6-2.2,7.3-3.5,10.9c-0.6,1.6-1.4,3-2.2,4.4
                c-1.4,2.2-1.3-0.2,1.7,0.1c1.3,0.3,2.5,0.7,3.8,1c0.8,0.4,2.3,3.4,1.9,2c0.4,1.5,0-1,0-1.1c0-0.9,0-1.7,0.1-2.6
                c0-0.3,0.3-3.3,0.2-2.1c0.1-0.6,0.2-1.2,0.3-1.9c0-0.2,0.1-0.3,0.1-0.5c-0.8,1.7-0.7,1.5,0.2-0.7c0.6-1.6,1.1-3.3,1.6-4.9
                c0.5-1.6,1.1-3,2-4.4c-0.6,1-4.9,0.7-4,0.9c-2.3-5-3.4-6.1-3.5-3.4c0,0.9-0.1,1.8-0.2,2.7c-0.9,7.6-2,15.2-4.5,22.5
                c4.8,1.3,9.6,2.7,14.5,4c3.6-10.9,8.8-24.9,2.1-35.6c-4.1-6.6-13.1-9.6-20.4-10c-8.5-0.5-16.3,4.8-22.2,10.4
                c-4.6,4.4-1.3,13.1,5.3,12.8c8.4-0.4,13.3-4.6,19.1-10.2c4.9-4.8,13.4-4.7,19.9-4.8c8.7-0.1,9.9-12,2-14.7
                c-3.1-1.1-5.7-3.9-8.4-5.7c-3.8-2.6-8.5-3.5-13.1-2.8c-10.6,1.7-17,11.8-25.3,17.3c3,3.9,6.1,7.9,9.1,11.8
                c0.5-0.7,2.8-4.1,2.5-3.8c1.6-1.2,2.2-3.2,3.9-3.8c4.5-1.7,8.1-4.7,12.2-7.4c1.9-1.2,4-1.7,5.9-2.8c1.9-1.2,3.8-2.5,6-3.2
                c3.5-1.1,3.4,5,3.8,7.4c1.9,11.7,5,23.1,8.6,34.4c1.8,5.4,3.8,10.6,6.4,15.7c2,4.1,5.8,7.9,7.2,12.2c3.7-2.8,7.3-5.6,11-8.5
                c-1.1-0.6-1.4-0.5-1.6-1.5c-4.6,1.9-9.1,3.9-13.7,5.8c3.1,7,6.8,13.6,11,20c4.1,6.2,9.5,11.4,13,18c4,7.5,16.1,2.3,13.7-5.8
                c-3.1-10.5-12-17.3-18.9-25.3c-2.2-2.5-6.3-2.8-9.1-1.2c-3.8,2.2-4.3,6.5-2.7,10.3c3.6,8.2,10.4,15.2,16.1,22
                c1,1.1,1.8,2.3,2.6,3.5c0.6,1.4,0.7,1.2,0.3-0.3c0.4,3.1,0.7-2.8,2.5-3.3c0.5-0.1-0.9-0.3-1.9-0.6c-0.9-0.3-0.3-0.4,0.1,0.9
                c-3.1,3.1-6.2,6.2-9.2,9.2c-5.4-1.9,0.8,0.6,2,1C350.2,379.4,354.1,364.9,345,361.8L345,361.8z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#D1D3D4" d="M328,361.8c1.2,0.4-2-1.1-2.6-1.4c-1.7-1-3.4-1.8-5.2-2.5c-4.5-1.7-8.6-0.9-12.2,2.1
                c-7.8,6.3-1.9,20.8,6.7,23.1c11.1,3,15.3-9.9,10.4-17.9c-4.2-6.7-12.1-13-20.1-13.6c-3.8-0.3-7.9,3.7-7.5,7.5
                c0.8,7.9,5.8,13,11.2,18.3c5.9,5.8,17-2.1,11.8-9.1c-3.6-4.8-8.3-7.9-12.6-11.8c-3.9-3.6-9.2-7.5-14.8-6.9
                c-3.8,0.4-7.9,3.1-7.5,7.5c0.7,8.4,6.7,15.3,13.2,20.3c2.7,2.1,5.9,3,9.1,1.2c3.3-1.9,5.1-6.9,2.7-10.3c-2.2-3-4.6-5.6-7.4-8
                c-2.2-1.9-4.9-3.6-6.6-6c-3.4,3.4-6.8,6.8-10.3,10.3c1.1,0.5,2.5,1.1,3.5,1.8c3-3.9,6.1-7.9,9.1-11.8c-1.7-0.9-3.3-2.1-5-3
                c-1.9,4.6-3.9,9.1-5.8,13.7c5.4,1.8,11,3.3,16.2,5.8c6,2.8,10.2,7.7,13.5,13.3c6.6,11.2,16.1,21.2,29.3,23.9
                c6.5,1.4,11.7-5,8.5-11c-5.3-10-15.5-16.6-20-27c-2.2,3.8-4.3,7.5-6.5,11.3c2.9-0.1,6.2,5.1,8.2,8.1c3,4.6,5.7,9.6,9.5,13.7
                c5,5.3,14.9-0.1,12.5-7.3c-3-9-6.2-17.9-9-27c-4.2,2.4-8.4,4.9-12.5,7.3c3.4,3.7,4.6,8.6,6.1,13.2c1.8,5.8,4.1,11,7.9,15.8
                c3.5,4.3,11.4,1.9,12.5-3.3c1.2-5.5-0.3-10.8-2-16c-1.8-5.4-1.4-10.8-4.8-15.8c-2.8-4.3-10.4-5.4-13,0c-2.2,4.7-1.8,9-0.3,13.9
                c1.7,5.5,3.4,11.1,5.7,16.4c2.5,5.9,5.8,11.4,8.6,17.1c2.8,5.5,5.4,11.1,9,16.2c3.4-3.4,6.8-6.8,10.3-10.3
                c-5.5-3-10.1-8.1-14.9-12c-5.3-4.4-9.5-9.6-14.3-14.5c-7.4-7.7-12.7-17.9-23.6-21.2c-4.2-1.3-7.7,1.5-9.2,5.2
                c-3.1,7.6,0.8,16.1,4.4,22.8c4.4,8.2,11.5,15.5,18.5,21.5c3-3.9,6.1-7.9,9.1-11.8c-5.1-2.8-8.9-7.2-12.7-11.6
                c-3.6-4.3-7.4-8.6-9.7-13.7c-4.3,2.5-8.6,5-13,7.6c3.8,6.4,8.8,11.8,14.1,17.1c5.9,5.9,11.5,12.2,17.2,18.3
                c5.6,6.1,12.1,10.6,18.9,15.3c6.7,4.7,11.8,10.2,20.4,11c9.6,0.8,9.6-14.2,0-15c-2.3-0.2-4.7-2.6-6.4-4c-2.5-2-5.1-4-7.7-5.8
                c-6.4-4.5-11.7-9-17-14.8c-4.5-4.9-8.9-9.8-13.6-14.5c-4.7-4.7-9.4-9.3-12.8-15.1c-5-8.5-16.8-1.1-13,7.6
                c2.8,6.2,7.7,11.5,12,16.8c4.4,5.4,9.5,10.6,15.7,13.9c7.2,4,15.8-6.1,9.1-11.8c-5.1-4.3-10.9-9.6-14.4-15.4
                c-2.3-3.8-6.6-9.7-4.7-14.3c-3.1,1.7-6.2,3.5-9.2,5.2c4,1.2,7.1,5.5,9.7,8.6c3.5,4.1,6.8,8.2,10.4,12.2
                c4.2,4.8,8.8,9.2,13.8,13.2c5.1,4.1,9.5,9,15.3,12.2c6.2,3.4,14.7-3.8,10.3-10.3c-5.4-7.9-9.3-16.8-13.8-25.2
                c-2.5-4.6-4.4-9.4-6-14.4c-0.6-1.7-1-3.5-1.6-5.2c-0.3-0.9-0.6-1.7-0.8-2.6c-0.3-1-0.1-2.4-0.8-1c-4.3,0-8.6,0-13,0
                c2.1,3.3,2.3,7.3,3,11.1c0.5,2.7,2.9,6.4,2.3,9.1c4.2-1.1,8.4-2.2,12.5-3.3c-3-3.7-3.9-8.9-5.3-13.2c-1.9-5.9-4.4-11.2-8.7-15.8
                c-5.1-5.5-14.7,0.2-12.5,7.3c2.8,9.1,6,18,9,27c4.2-2.4,8.4-4.9,12.5-7.3c-8.4-8.9-12.9-26.8-28.3-26.2
                c-6.1,0.2-8.7,6.1-6.5,11.3c4.6,10.5,14.7,17,20,27c2.8-3.7,5.6-7.3,8.5-11c-13.4-2.8-17.5-14.8-25.4-24.3
                c-8.9-10.8-20.8-14.5-33.6-18.7c-8.2-2.7-13.1,9.6-5.8,13.7c1.7,0.9,3.3,2.1,5,3c7.2,4,15.9-6.2,9.1-11.8c-2.1-1.7-4.1-3-6.5-4.2
                c-6.2-3.2-14.8,3.6-10.3,10.3c3.8,5.6,10.1,8.6,14,14c3.9-3,7.9-6.1,11.8-9.1c-2.5-1.9-8.5-6.2-8.8-9.7c-2.5,2.5-5,5-7.5,7.5
                c1.2,0,1.3-0.1,0.3-0.2c0.8,0.4,1.5,0.8,2.2,1.3c1.3,0.9,2.4,2.1,3.6,3.2c2.6,2.5,6.3,4.2,8.4,7c3.9-3,7.9-6.1,11.8-9.1
                c-1.8-1.8-6.6-5.2-6.8-7.7c-2.5,2.5-5,5-7.5,7.5c-1.3-0.1,0.7,0.6,1,0.7c1.1,0.6,2,1.4,3,2.2c1.1,1,2.1,2.1,3.1,3.2
                c0.1,0.3,0.3,0.6,0.5,0.9c-0.8-1.2,1.6-4,1.7-4c1.3-0.3,2.5-0.7,3.8-1c1.7-0.1,2.1,1.3,1-0.6c2,3.5-6.2,3-3.9,4.1
                c3,1.4,5.5,3.3,8.7,4.3C333.2,379.2,337.2,364.7,328,361.8L328,361.8z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#D1D3D4" d="M369.8,422.5c-1-0.7-2-1.3-3-2c-4.5-3.4-11.6,1.1-11.3,6.5c0.7,11,9.5,17.4,18.2,22.7
                c3.9,2.4,8.1,4.2,11.6,7.3c4.9,4.4,7.7,8.7,14.7,9.5c7.6,0.9,10-10.6,3.8-14c-6.6-3.6-11.3-9.3-16.4-14.7
                c-5.9-6.2-11.6-12.5-17.1-19.1c-5.6-6.8-15.8,1.9-11.8,9.1c2.3,4.3,5.8,7.6,9.4,10.8c3.2,2.9,7.6,6,8.8,10.4
                c2.4-3.2,4.8-6.3,7.2-9.5c-15.6,1.1-22.6-19.1-36-23.7c-5.9-2.1-10.1,4.1-9.2,9.2c3.5,21.7,25.2,32.9,43,41.8
                c7.6,3.8,16.2,8,24.9,8c4.4,0,8.5-2.1,10.8-5.9c4-6.7-2.8-14.7-7.2-19.1c-6.8-6.9-17.4,3.7-10.6,10.6c1.1,1.1,3.6,3,3.8,4.7
                c0.3-1.3,0.7-2.5,1-3.8c0.4-0.7,2.7-1.7,1.4-1.4c2.1-0.5-1.7-0.3-0.3-0.1c-2.8-0.4-5.6-1.4-8.3-2.4c-5.4-2-10.5-4.7-15.5-7.5
                c-12.2-6.7-26.2-13.7-28.6-28.9c-3.1,3.1-6.2,6.2-9.2,9.2c3.1,1.1,5.2,4.1,7.3,6.4c2.5,2.8,5,5.3,7.9,7.7
                c7.1,5.7,15.3,10.8,24.8,10.2c4.8-0.3,8.6-4.5,7.2-9.5c-1.5-5.3-4.8-9.5-8.7-13.4c-3.7-3.7-8.5-6.8-11-11.4
                c-3.9,3-7.9,6.1-11.8,9.1c5.8,7,11.9,13.7,18.2,20.3c5.7,5.9,11,12,18.3,15.9c1.3-4.7,2.5-9.3,3.8-14c2.4,0.3-1.1-2.2-1.9-3
                c-1.4-1.4-2.9-2.8-4.5-4.1c-3.2-2.7-6.9-4.4-10.4-6.5c-3.5-2.1-12.5-6.1-12.7-10.9c-3.8,2.2-7.5,4.3-11.3,6.5c1,0.7,2,1.3,3,2
                c3.3,2.4,8.4,0.5,10.3-2.7C374.7,429,373.1,425,369.8,422.5L369.8,422.5z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#D1D3D4" d="M384.3,433.3c0,0,0.1-0.1,0.1-0.1c2.6-2.3,2.6-6.2,1.2-9.1c-2.2-4.3-7.5-7.5-10.6-11.1
                c-5.4-6.3-8.8-14.4-12.5-21.8c-4.3,2.5-8.6,5-13,7.6c3.8,6.7,10.1,11,15.3,16.5c6.2,6.6,11.9,13,19.4,18.2
                c4.5,3.1,11.8-1,11.3-6.5c-0.8-8.6-7-16.4-11.5-23.4c-5.4-8.3-11-16.5-15.5-25.3c-4.1-8.1-15-2.1-13.7,5.8
                c0.9,5.5,4.7,9.9,8.8,13.4c3.5,3,8.4,5.5,10.9,9.4c4.4,6.9,16.6,2.5,13.7-5.8c-1.7-4.8-5.3-7.6-7.8-11.8
                c-4.3-7.4-16-2.4-13.7,5.8c1.7,6.2,6.3,10.5,9.9,15.5c4,5.5,8.8,10.3,12.8,15.8c3.7,5.1,8.3,9.6,13.6,13.2
                c5.4,3.6,11.5,2.5,17.2,5c1.3-4.7,2.5-9.3,3.8-14c-8.5-0.1-16.9-0.8-25.3-1.6c-2.5-0.2-6.3,0.3-8.5-0.8c-1.3-0.9-1.5-1-0.7-0.3
                c-0.7-0.9-1.4-1.9-2.2-2.8c-1.2-1.5-2.6-2.9-4-4.3c-6.8-6.9-17.4,3.7-10.6,10.6c3,3,5.3,6.6,8.8,9.1c3.5,2.5,7.8,3,12,3.1
                c10.2,0.2,20.3,1.9,30.6,2c7,0,10.9-10.9,3.8-14c-4.9-2.1-9.7-2.6-14.8-3.8c-4.4-1-9.1-7-11.6-10.5c-3.4-4.7-7.4-8.9-11.1-13.3
                c-3-3.5-7.8-8.5-9.1-12.9c-4.6,1.9-9.1,3.9-13.7,5.8c1.6,2.8,5.2,5.3,6.2,8.2c4.6-1.9,9.1-3.9,13.7-5.8
                c-2.8-4.4-6.7-7.4-10.8-10.5c-3-2.3-6.7-4.7-7.4-8.7c-4.6,1.9-9.1,3.9-13.7,5.8c4,7.8,8.7,15.2,13.6,22.5
                c2.3,3.4,4.5,6.8,6.4,10.5c1.3,2.6,4.7,5.2,5,8.2c3.8-2.2,7.5-4.3,11.3-6.5c-6.3-4.3-11.4-10-16.3-15.8c-4-4.8-9.9-7.9-13-13.5
                c-4.8-8.5-17.2-1-13,7.6c3.6,7.1,7,14.3,11.7,20.8c2.2,3,4.6,6,7.4,8.5c1,0.9,1.9,1.7,2.9,2.6c0.7,0.6,2.2,3,1.2,1
                c0.4-3,0.8-6.1,1.2-9.1c0,0-0.1,0.1-0.1,0.1C366.5,429.1,377.1,439.7,384.3,433.3L384.3,433.3z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#D1D3D4" d="M418.8,441c0,0.1,0,0.1-0.1,0.2c1.1-1.5,2.3-3,3.4-4.5c0.8-0.6,3.9-0.7,1.5-0.8c-0.8-0.1-0.9-0.1-0.1,0
                c-0.6-0.1-1.1-0.2-1.7-0.3c-2.6-0.5-5.2-1-7.8-1.6c-4.3-0.9-8.7-2.3-13.1-2.5c-9.7-0.5-9.6,14.5,0,15c0.7,0,0.4,0-0.8-0.1
                c0.6,0.1,1.2,0.2,1.8,0.3c1.4,0.3,2.8,0.6,4.2,0.9c3.3,0.7,6.6,1.5,9.9,2.1c6.2,1.2,14.5,3.1,17.2-4.7
                C436.4,435.8,421.9,431.9,418.8,441L418.8,441z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#D1D3D4" d="M417,467.8c-6.5,2.5-9.2,8.4-11.9,14.4c-2.9,6.4-6.9,12.8-7.6,19.9c-0.6,6.4,8.7,10,12.8,5.3
                c1.4-1.6,2.4-3.2,2.9-5.3c2.5-9.4-12-13.3-14.5-4c0.3-0.4,0.6-0.9,0.9-1.3c4.3,1.8,8.5,3.5,12.8,5.3c0.2-1.8,1.7-3.8,2.5-5.4
                c1-2,1.7-4,2.7-6c0.8-1.7,1.5-3.4,2.3-5.1c0.5-1.1,1.1-2.1,1.7-3.1c0.8-1.4,1-0.9-0.6-0.3C429.9,478.8,426.1,464.3,417,467.8
                L417,467.8z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#D1D3D4" d="M426.8,450c0.9,4.5,4.9,7,7.1,9.8c2.3,2.8,5.9,6.3,5.6,10.2c5,0,10,0,15,0c0-0.7,0-1.3,0-2
                c0-9.7-15-9.7-15,0c0,0.7,0,1.3,0,2c0,9.8,14.1,9.5,15,0c0.5-5.4-2.1-10.4-5.1-14.6c-1.5-2.1-3.2-4.1-4.9-6.2c-0.8-1-1-1.3-1.7-2
                c-0.5-0.4-1-0.8-1.5-1.3c-1-1-1-1-0.1,0.1C439.4,436.5,424.9,440.5,426.8,450L426.8,450z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#D1D3D4" d="M298.2,239c-1.4-7.8-11.8-10.2-18-6.5c-4.2,2.5-8.4,8.6-9.5,13.2c-1.6,6.9,4.9,13.9,12,11.5
                c5.8-2,8.9-8.8,10.4-14.3c0.7-2.4,1.1-3.7,1.9-7c0.9-3.7,1.1-7.4-0.1-11c-2.7-8.2-13.4-8.9-16.1-0.3c-0.7,2.2-0.9,4.5-1.1,6.8
                c-0.1,0.8-0.8,2.6-0.2,1.4c-0.9,1.8-2.3,3.3-3.7,4.7c-5.1,5.4-10.6,9.5-11.3,17.4c-0.4,3.9,3.7,7.7,7.5,7.5
                c13.7-0.7,23.2-13.7,29-24.9c3.4-6.6,5.3-13.6,6.2-20.9c1.1-8.4-1.9-15.2-5-22.7c-1.6-3.8-4.9-6.4-9.2-5.2
                c-3.6,1-6.8,5.4-5.2,9.2c2.1,5.1,4.8,9.9,4.7,15.5c-0.1,5.3-1.7,10.7-3.9,15.5c-2.7,5.8-9.1,18.1-16.6,18.5c2.5,2.5,5,5,7.5,7.5
                c0-0.2,3.3-3,4.2-3.9c2.8-2.9,5.6-6,7.9-9.4c1.8-2.6,2.8-5.7,2.9-8.8c0-0.4,0.3-2.6,0.2-2.1c0.5-3.1,0.1,0.4-2.2,1.3
                c-2.5,0-5,0-7.6,0c-3.1-1.4-1.8-3.6-2.5-0.2c-0.4,2-1.1,4-1.8,6c-0.3,2.3-0.3,2.7-0.2,1c-0.2,0.8-0.5,1.6-0.8,2.3
                c-0.1,0.4-0.7,1.4-1,1.8c-1.1,2-0.3-0.5,2.6-0.4c1.3,0.3,2.5,0.7,3.8,1c-2.9-1.2,3,3.4,2,5.4c0.5-0.9,1.1-1.7,1.8-2.5
                c0.5-0.6,1.1-1.2,1.6-1.7c0.9-0.9,0.4-0.6-1.5,1c1.9,0.1,0.8-0.9-3.4-2.9C285.5,252.5,299.9,248.5,298.2,239L298.2,239z"/>
        </g>
    </g>
    <path fill="#D1D3D4" d="M295.8,175.9c-2.9-3.1-6.5-5.8-10.4-7.7c-0.1,0.5-0.7,1-1.2,0.8c-3.8-1.5-7.7-2.7-11.7-3.6
        c-6,0-12,1.3-17.8,2.6c-14.1,3.2-29.6,11.8-33,27.1c-1.5,6.7,6.1,12.2,11.6,8.2c1,0,2.3,0.4,3.3,0.7c2.8,0.7,5.5,1.8,8.3,2.7
        c7.8,2.7,15.2-0.8,23.1-1.1c4-0.2,7.2-3.1,7.5-7.1c2,0.4,4,0.9,6,1.7c0.1,0,0.3,0.2,0.8,0.5c0.8,0.4,1.6,0.8,2.4,1.1
        c2.5,4.9,4.2,10.6,5.3,15.4c1.7,7.2-0.6,12.4-4.5,18.2c-4,6.1,4.2,14.7,10.3,10.3C318.3,229,313.1,194.4,295.8,175.9z M256.1,192.2
        c0,0,0,0.1,0,0.1c-0.1,0-0.1,0-0.2,0C256,192.3,256.1,192.2,256.1,192.2z"/>
    <g>
        <g>
            <path fill="#D1D3D4" d="M339.7,245.7c-0.2,0.2-0.4,0.4-0.6,0.6c1.8-0.7,3.5-1.5,5.3-2.2c-0.2,0-0.5,0-0.7,0
                c1.3,0.3,2.5,0.7,3.8,1c-2.7-1.5-4.7-3.1-7.7-4c-3.1-0.9-6.1-2-9.3-2.5c-4-0.6-7-0.1-10.7,1.5c-2.5,1.1-5,4.1-7.8,4.3
                c-9.5,0.8-9.8,15,0,15c5.7,0,11.3-0.4,16.8-2c5.3-1.5,8.4-3.2,12.6,1c7,7.1,7.1,17.9,15.8,24c5.7,4,12.4-2.5,11-8.5
                c-3.8-15.8-17-25.6-28.9-35.3c-1.8,4.3-3.5,8.5-5.3,12.8c4.3,0.3,8.9,7.2,11.2,10.4c4.2,5.8,8.4,11.1,10.6,18.1
                c2.6,8.5,5.3,16.9,7.9,25.3c1.7,5.6,5.5,11.9,5.9,17.7c0.5,9.6,15.6,9.7,15,0c-0.5-8.1-4.6-15.8-7-23.5c-3-9.9-5.6-20.3-9.6-29.8
                c-3.3-7.7-8.9-14.6-14.2-21.1c-5-6.2-11.6-11.5-19.8-12.1c-6.2-0.4-10.3,8.7-5.3,12.8c9.7,7.9,22,15.8,25.1,28.7
                c3.7-2.8,7.3-5.6,11-8.5c-2.9-2-4.3-8-5.7-11.1c-2.3-5.3-6.1-10.2-10.7-13.7c-5.4-4.1-11.1-4.6-17.6-3.3c-3,0.6-5.8,1.7-8.7,2.4
                c-3.2,0.9-6.7,0.7-10,0.7c0,5,0,10,0,15c3.8-0.3,7-1.3,10.3-3.3c1.7-1,3.3-2.1,5.1-3.1c-0.1,0.1,2.7,0.7,3.2,0.9
                c1.8,0.5,3.5,1.1,5.3,1.6c1.6,0.5,1.1,0.7,3.3,2c3.9,2.2,8.1,2.1,11.2-1.3C356.9,249.3,346.3,238.6,339.7,245.7L339.7,245.7z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#D1D3D4" d="M373.5,348c0,0.3,0,0.7,0,1c1.2-2.2,2.5-4.3,3.7-6.5c-6.7,3.3-13.7,7.1-16.4,14.5c-1.8,5,2.8,9,7.2,9.5
                c10.2,1,19.1-4,25.5-11.6c6.5-7.8,6.5-16.2,2.5-25.1c-4.3-9.5-10.6-18.4-16-27.4c-6.2-10.2-12.1-20.6-17.5-31.2
                c-3.9,3-7.9,6.1-11.8,9.1c6,7.5,12.8,13.7,18,22c5.6,9,10.2,18,14.9,27.5c3.4-3.4,6.8-6.8,10.3-10.3c-4.2-2.1-6-10.8-7.4-14.9
                c-2.8-8.3-5.6-16.3-9.7-24.1c-3.5-6.8-8.1-13.3-13.9-18.3c-4.9-4.2-10.8-8-16.1-11.8c-5.4-3.8-11.5-6.1-18.1-4.8
                c-7.4,1.4-11.2,9.2-12.2,15.9c-2.3,15.3,4.8,30.6,16.2,40.8c5.7,5.1,14.2,0.2,12.5-7.3c-1.5-6.7-5.9-12.2-9.7-17.7
                c-4.2-6-7.3-12.8-11-19.1c-4.1-6.9-13.3-3.3-14,3.8c-0.7,6.6,2.4,12.8,5,18.6c3.2,7.3,6.8,14.1,11.1,20.9
                c8.8,13.7,20.3,25.2,30,38.3c3.9-3,7.9-6.1,11.8-9.1c-6.5-8.3-14.1-17.2-18-27c-3.5-8.8-6.4-17.9-13-25
                c-4.9-5.2-12.1-0.7-12.8,5.3c-0.7,6,1.1,11.8,3.2,17.3c2.2,5.8,4.4,11.7,7.1,17.3c5.2,10.9,11.5,22.1,18.9,31.6
                c4.7,6,14.7-0.5,12.5-7.3c-2.9-9-8.9-15.9-13.5-24c-2.2-4-5-7.6-7.4-11.5c-2.5-3.8-3.5-8.4-5.8-12.4c-3.7-6.2-14.3-3.8-14,3.8
                c0.4,10.3,6.1,19.1,12.3,27.1c6.7,8.7,12.2,18.3,19.9,26.2c4.3,4.4,13.4,1.4,12.8-5.3c-0.7-8.1-6.3-15.7-12.7-20.5
                c-3.1-2.3-8.7-0.7-10.3,2.7c-6.2,13.3,13.3,31.8,25.6,22c7.7-6.1,1.3-18.9-2.2-25.4c-5.1-9.5-8.7-19.9-13.5-29.6
                c-1.4-2.9-5.5-4.5-8.5-3.4c-12.6,4.2-2.6,19.9,1.4,26.8c3.1,5.3,6.5,10.5,10,15.5c2.5,3.5,5,7.5,8.2,10.3
                c6.5,5.6,15.6-1.3,11.8-9.1c-4.7-9.5-8.8-19.7-16.2-27.5c-4.4-4.6-13.2-1.3-12.8,5.3c0.4,6.8,4.3,13.2,8.3,18.5
                c4.5,6.1,10.2,12.4,18.2,13c2.7,0.2,5.1-1.6,6.5-3.7c6.4-9.9-4-22.8-8.4-31.4c-5.8-11.2-8.2-24.4-19.2-31.9
                c-3.8-2.6-10.2-0.1-11,4.5c-2.5,14.5,7.2,31.9,18.4,40.5c4.3,3.3,12-1.1,11.3-6.5c-0.7-5.4-3.9-9.8-6-14.8
                c-2.3-5.4-4.4-10.9-7.1-16.1c-4.6-8.9-7.9-17.7-16.6-23.6c-5.4-3.6-13.1,2.4-11,8.5c5,14.4,13,29.4,24.9,39.3
                c5.8,4.8,14.1,0.3,12.5-7.3c-1.6-8.1-7-16.3-11.2-23.2c-4.2-6.7-11.1-18.2-20.6-16.1c-19.8,4.4,1.6,39.9,11.8,44.8
                c4.4,2.1,9.6,0.5,11-4.5c2.5-8.6-3.4-17.5-8.3-24.1c-2.6-3.6-4-8.1-6.4-11.9c-2.7-4.3-6.3-8.2-10.8-10.5
                c-3.4-1.7-8.4-1.2-10.3,2.7c-3.3,7,0.2,14.1,3.9,20.2c3.2,5.3,8,12.3,13.8,15c3.9,1.8,10.3,0.7,11-4.5c0.6-4.4-0.1-7.9-1.9-11.9
                c-1.3-3-4-6.5-4.6-9c-2-7.6-6-16.3-13.8-19.3c-3.8-1.4-8.2,1.6-9.2,5.2c-3.9,14.2,8.2,30.1,19.4,37.5c6.3,4.1,14.3-4,10.3-10.3
                c-2.3-3.6-4.3-7.3-7.2-10.5c-6.4-7.3-17,3.4-10.6,10.6c1.9,2.2,3.2,5,4.8,7.5c3.4-3.4,6.8-6.8,10.3-10.3
                c-5.3-3.5-14.5-13.4-12.6-20.5c-3.1,1.7-6.2,3.5-9.2,5.2c-2.1-0.8-1.1-0.7-0.4,0.4c0.7,1,1.2,2.2,1.8,3.3
                c1.1,2.2,1.8,4.7,2.3,7.1c0.5,2.1,2.3,4.3,3.4,6.1c0.6,1.1,1.2,2.2,1.7,3.4c0.5,1,0.5,4.1,0.8,1.5c3.7-1.5,7.3-3,11-4.5
                c-1-0.5-1.4-0.9-3-2.5c-1.6-1.6-2.9-3.4-4.2-5.2c-1.5-2.1-2.7-4.3-3.8-6.6c-0.2-0.4-0.4-1.3-0.6-1.7c-0.4-1.2,0.3-0.9-0.7,1.3
                c-3.4,0.9-6.8,1.8-10.3,2.7c5.7,2.8,8.2,9.9,10.8,15.3c1.2,2.6,3.3,4.9,4.8,7.3c1.1,1.8,3.3,5.8,2.9,6.9c3.7-1.5,7.3-3,11-4.5
                c-1.5-0.7,0.8,0.9-0.9-0.7c-0.9-0.9-1.8-1.9-2.6-2.9c-1.6-2-3.5-5-4.9-7.5c-1.5-2.6-2.7-5.1-3.6-7.9c-0.6-2-0.4-0.5-0.4-2.3
                c0,1.5-0.8,2.5-2.1,3.4c-1.4,0.2-2.8,0.3-4.1,0.5c-1.1-0.5-1.2-0.6-0.5-0.2c0.7,0.4,0.6,0.4,0-0.1c0.6,0.5,1.1,1,1.6,1.6
                c0.9,0.9,1.6,1.8,2.2,2.7c2.3,3.2,4.4,6.5,6.3,10c2.9,5.1,5.9,10,6.9,14.9c4.2-2.4,8.4-4.9,12.5-7.3c-10-8.3-16.9-20.6-21.1-32.7
                c-3.7,2.8-7.3,5.6-11,8.5c5.8,4,8.2,12.2,11.2,18.2c2,4.1,3.8,8.4,5.6,12.6c1,2.3,2,4.7,3,7c1,2.2,2.4,4.1,3.3,6.2
                c-0.3-1.3-0.7-2.5-1-3.8c0.1,0.4,0.1,0.9,0.2,1.3c3.8-2.2,7.5-4.3,11.3-6.5c-5.1-3.9-12.7-16.9-11.6-23.5c-3.7,1.5-7.3,3-11,4.5
                c3.7,2.5,5.6,7.6,7.4,11.4c2.1,4.6,3.7,9.3,5.9,13.9c1.7,3.5,3.8,6.9,5.6,10.4c1,1.8,1.9,3.6,2.6,5.6c-0.1-0.3,0.7,2.7,0.6,1.7
                c0.2,1.8-0.1,0.2,0.2-0.3c2.2-1.2,4.3-2.5,6.5-3.7c2,0.2-0.3-0.4-1.2-1.2c-1.8-1.5-3.4-3.4-4.8-5.3c-1.6-2.1-3-4.4-4.2-6.8
                c0.2,0.4-1.1-2.5-0.8-1.7c-0.2-0.5-0.6-3.4-0.5-1.5c-4.3,1.8-8.5,3.5-12.8,5.3c6.4,6.7,9.8,16.3,13.8,24.5
                c3.9-3,7.9-6.1,11.8-9.1c-4.9-4.2-8.3-10.5-12.1-16.5c-2.5-3.9-4.9-7.9-7.1-12.1c-0.4-0.7-0.8-1.5-1.1-2.3
                c-0.2-0.5-0.4-0.9-0.6-1.4c0.3,0.7,0.3,0.7,0-0.1c-0.2-0.5-0.4-1.1-0.5-1.6c-0.2-0.9-0.2-0.6,0,0.7c0-2.9-1.6-1-4.8,5.7
                c-2.8-1.1-5.6-2.3-8.5-3.4c3.2,6.5,6.4,12.9,9,19.7c2,5.2,4.6,9.7,6.9,14.7c0.5,1,0.7,2.1,1,3.2c0.6,2.3-1,1.7,1.1-0.7
                c1.3-1.5,4.5-1,3-1.4c1.2,0.4-1.1-0.8-1.1-0.8c-0.6-0.4-2-2-2.4-2.6c0,0.1-1.3-2.3-0.8-1.1c-0.5-1.3-0.2,0.9-0.8,2.1
                c-3.4,0.9-6.8,1.8-10.3,2.7c1.3,1,5.1,6,5.3,7.5c4.3-1.8,8.5-3.5,12.8-5.3c-6.7-6.9-11.1-15.5-17.3-22.9
                c-4.5-5.4-10.2-12.5-10.5-19.8c-4.7,1.3-9.3,2.5-14,3.8c2.1,3.5,3.3,7.4,5,11c1.9,4,5,7.6,7.4,11.3c4.4,6.9,10.4,14.1,12.9,21.9
                c4.2-2.4,8.4-4.9,12.5-7.3c-6.3-8.1-11.2-18-15.8-27.1c-2.3-4.5-3.9-9.2-5.8-13.9c-1.9-4.8-3.8-9.5-3.2-14.7
                c-4.3,1.8-8.5,3.5-12.8,5.3c6.2,6.6,7.6,16.2,11.6,24.1c5.2,10.1,12.4,19,19.4,27.9c5.2,6.7,16.8-2.2,11.8-9.1
                c-8.9-12.1-19.5-22.9-28-35.3c-3.8-5.5-8.4-11.8-10.7-18.1c-1.9-5.4-5.9-10.9-5.3-16.8c-4.7,1.3-9.3,2.5-14,3.8
                c3.5,5.9,6.5,12.2,10.3,18c3.2,4.8,7.7,9.5,9,15.2c4.2-2.4,8.4-4.9,12.5-7.3c-7.9-7.1-14.1-17.8-11.9-28.7
                c0.7-3.7,1.7-3.2,4.7-1.6c4.6,2.5,8.4,6.1,12.6,9.1c10,7.1,16.4,17.5,20.3,29.1c2.2,6.5,4.3,13,6.9,19.4
                c2.2,5.3,4.9,10.8,10.3,13.5c6.9,3.4,13.6-3.4,10.3-10.3c-4.7-9.5-9.3-18.5-14.9-27.5c-2.8-4.5-5.6-8.8-9.1-12.8
                c-3.6-4.2-7.8-7.9-11.2-12.3c-5.6-7-15.4,1.9-11.8,9.1c7.9,15.7,17.2,30.6,26.2,45.6c2.1,3.4,4.1,6.9,6,10.4
                c0.9,1.6,2.6,4.3,2.3,6.3c-1,7.2-9.8,11-16.1,10.3c2.4,3.2,4.8,6.3,7.2,9.5c-0.7,1-0.6,1,0.4-0.1c0.8-0.8,1.8-1.3,2.7-1.9
                c2.1-1.3,4.3-2.4,6.5-3.5c2.3-1.2,3.7-4,3.7-6.5c0-0.3,0-0.7,0-1C388.5,338.3,373.5,338.3,373.5,348L373.5,348z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#D1D3D4" d="M385.5,344c0,0.9,0.1,1.7,0.3,2.6c0.4,1.6,1.1,2.3,2.1,3.6c0.8,1.1,2.7,2,4,2.3c1.1,0.2,2.1,0.4,3.2,0.2
                c0.6-0.1,1.3-0.2,1.9-0.2c4.1-0.1,7.5-3.4,7.5-7.5c0-2.3-0.6-4.5-2.3-6.2c-1.7-1.7-3.9-2.3-6.2-2.3c-4.1,0-7.5,3.4-7.5,7.5
                c0,4.1,3.4,7.5,7.5,7.5c0.2,0,0.4,0,0.6,0c-0.7-0.1-1.3-0.2-2-0.3c0.1,0,0.1,0,0.2,0.1c-0.6-0.3-1.2-0.5-1.8-0.8c0,0,0,0,0.1,0
                c-0.9-0.9-1.8-1.8-2.7-2.7c0,0,0,0,0,0.1c-0.3-0.6-0.5-1.2-0.8-1.8c0,0.1,0,0.1,0.1,0.2c-0.1-0.7-0.2-1.3-0.3-2
                c0,0.2,0,0.4,0,0.6c2.5-2.5,5-5,7.5-7.5c-1,0-2.1,0.4-3.1,0.2c0.7,0.1,1.3,0.2,2,0.3c-0.1,0-0.2,0-0.3-0.1
                c0.6,0.3,1.2,0.5,1.8,0.8c-0.1,0-0.2-0.1-0.2-0.1c0.5,0.4,1,0.8,1.5,1.2c-0.1-0.1-0.1-0.1-0.2-0.2c0.4,0.5,0.8,1,1.2,1.5
                c0,0,0-0.1-0.1-0.1c0.3,0.6,0.5,1.2,0.8,1.8c0-0.1-0.1-0.2-0.1-0.3c0.1,0.7,0.2,1.3,0.3,2c0-0.1,0-0.3,0-0.4
                c-0.2-4.1-3.3-7.5-7.5-7.5C389,336.5,385.3,339.9,385.5,344L385.5,344z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#D1D3D4" d="M274.7,299.3c1.9,1.8,3.9,3.2,6.7,3.1c4.1-0.1,7.4-3.3,7.5-7.5c0-1.4-0.3-2.6-0.6-3.9
                c-1.3-4.6-6.9-7.1-11-4.5c-5.8,3.6-9.5,8.8-13.9,13.9c-4.5,5.2-11.3,8.4-17.6,10.6c-16,5.4-31.2-2.3-46.7-5.2
                c-4.6-0.9-9.7,2-9.5,7.2c0.4,10.8,12,12.5,20.6,14.6c4.7,1.2,9.5,1.6,14.3,2.7c4.5,1,9,2,13.6,2.2c0-5,0-10,0-15
                c-8.3-0.1-16.5-1.9-24.7-2c-3,0-6.1-0.1-9.1-0.7c-2.8-0.5-4.7-2.1-6.9-3.8c-5.9-4.4-12.5-6.9-19.7-4.1c-3.5,1.4-6.3,3.8-9.3,5.9
                c-3,2-6.8,3.7-10.2,5c3.2,2.4,6.3,4.8,9.5,7.2c0-0.3,0-0.7,0-1c-0.7,1.8-1.5,3.5-2.2,5.3c4.3-3.7,7.7-8.6,12.4-11.8
                c2.8-1.9,4.3-0.5,7.3,0.7c3.4,1.3,7.4,1.3,11,1.3c9.8,0,9.5-14.3,0-15c-4.5-0.3-8.3-3.6-13-4c1.8,4.3,3.5,8.5,5.3,12.8
                c-0.1,0-0.2,0-0.3-0.1c9.3-2.7,5.3-17.2-4-14.5c-2.6,0.8-4.4,2.1-6.3,3.9c-5.1,4.9-0.9,12.2,5.3,12.8c1.7,0.2,3.8,1.7,5.4,2.3
                c2.5,1,4.9,1.5,7.6,1.7c0-5,0-10,0-15c-4.9,0-8.4-1.7-13-2.9c-5.1-1.3-10,1-14.1,3.8c-3.8,2.6-6.8,5.9-10,9.2
                c-3.1,3.3-6.4,5.3-6.4,10.3c0,4.5,4.9,9,9.5,7.2c6.7-2.5,12.8-5.7,18.6-9.9c2.9-2.1,4-1.9,6.8,0c2.6,1.6,4.8,3.8,7.6,5.2
                c5.6,2.7,12.2,2.9,18.3,3c8.3,0.2,16.4,1.9,24.7,2c9.7,0.1,9.6-14.6,0-15c-6.2-0.2-12.5-2.3-18.6-3.3c-6.6-1-13.1-3.1-19.4-5.5
                c1.5,1.1,3,2.3,4.5,3.4c0,0.3,0,0.5,0,0.8c-3.2,2.4-6.3,4.8-9.5,7.2c8.3,1.6,16.2,4.6,24.5,6.2c9.5,1.9,19.2,2,28.6-0.5
                c7.8-2.1,15.9-6.2,22.2-11.4c5.4-4.5,8.5-11.4,14.5-15.1c-3.7-1.5-7.3-3-11-4.5c0,0,0.1,0,0.1-0.1c2.5-2.5,5-5,7.5-7.5
                c-0.1,0-0.2,0-0.3,0c1.3,0.3,2.5,0.7,3.8,1c0.1,0.1,0.3,0.2,0.4,0.2C278.4,282,267.8,292.5,274.7,299.3L274.7,299.3z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#D1D3D4" d="M51.8,322.5c-6.1-2.9-10.6-7.8-14.3-13.3c-2.8,3.7-5.6,7.3-8.5,11c5.7,1.9,8.2,8.7,11.7,13.1
                c5.4,6.7,9.6,14.1,15.2,20.6c5.1,5.9,10.1,11.7,14.9,17.8c2.3,2.9,7.5,8.5,7.7,11.3c2.5-2.5,5-5,7.5-7.5
                c-5.4,0.3-9.9-7.1-12.8-10.8c-2.5-3.2-4-6-5.6-9.7c-1.4-3.3-2.6-7.1-5.8-9.1c-3.8-2.3-10.3-0.3-11,4.5c0,0.2-0.1,0.4-0.1,0.7
                c-0.6,4,1,8.1,5.2,9.2c3.6,1,8.6-1.2,9.2-5.2c0-0.2,0.1-0.4,0.1-0.7c-3.7,1.5-7.3,3-11,4.5c-2.3-3.4-3.1-4.4-2.6-2.8
                c0.5,1.2,1,2.3,1.6,3.5c1.2,2.5,2.2,5.1,3.4,7.7c1.8,3.8,5.3,7.4,7.9,10.7c5.3,6.5,12.4,13.1,21.4,12.6c3.8-0.2,7.8-3.3,7.5-7.5
                c-0.6-7.2-5-12.8-9.3-18.3c-4.9-6.3-9.9-12.7-15.4-18.8c-6.3-7.1-11.9-15-17.1-22.9c-4.9-7.5-9.9-14.2-18.7-17.2
                c-6.1-2.1-12.1,5.7-8.5,11c5.1,7.5,11.3,14.7,19.7,18.7C52.9,339.6,60.5,326.6,51.8,322.5L51.8,322.5z"/>
        </g>
    </g>
    <path fill="#D1D3D4" d="M173.2,354.7c-0.1,0.3-0.2,0.7-0.2,1c-0.3,1.1-2,0.9-2-0.3c0-1.6-0.1-3.2-0.5-4.7c-0.2-0.7,0.4-1.2,1-1.2
        c0-0.1,0-0.1,0-0.2c-0.6-0.3-1.3-0.6-2.1-0.7c0,0,0,0.1,0,0.1c-0.6,3.4,0.9,7.1,2.9,9.9c0.5,0.6,1,1.2,1.5,1.8
        c0.4-0.5,0.7-1.1,0.9-1.8C174.2,357.4,173.7,356.1,173.2,354.7z"/>
    <path fill="#D1D3D4" d="M167.5,348.5H167c-2.1,0-3.7,0.7-4.9,1.8c-5.5,2.4-10.6,5.9-15.9,8.8c-6.7,3.7-14.4,6-21.4,9.3
        c-7.1,3.3-13.9,7.3-21,10.6c-3.1,1.4-6.8,2.3-9.6,4.3c-1.3,0.9-2.7,2.3-4.1,3.2c-1.1-2.9-3.8-5-7-5h-1c-5.6,0-9.4,6.4-6.5,11.3
        c0.1,0.1,0.1,0.2,0.2,0.3c0.3-0.1,0.7-0.1,1,0.2c2.4,1.8,4.1,4,6.1,6.2c2.6,2.7,6,2.5,9.4,1.6c1.9-0.5,3.8-1.1,5.6-1.7
        c2.7-1.7,5.1-3.8,8.2-5.1c4.6-1.9,9-4,13.4-6.3c8.6-4.5,17.2-8.8,26.2-12.4c4.4-1.8,8.5-3.6,12.5-6.1c3.8-2.3,7.5-4.8,11.7-6.2
        c0.9-0.3,1.7-0.7,2.4-1.2C168.9,358.4,166.8,352.8,167.5,348.5z"/>
    <g>
        <g>
            <path fill="#D1D3D4" d="M290.2,320c-0.6-3.2-2.6-5.2-4.9-7.3c-3.7-3.3-9-2.7-11.8,1.5c-5.7,9,1.4,16.6,5,24.6
                c3.1,6.8,14.4,3.4,14-3.8c-0.5-8-4.7-17.2-11.7-21.5c-5.3-3.2-10.7,1.1-11.3,6.5c-1.2,10.9,3.3,23.5,12.7,29.5
                c6.4,4.1,14.1-3.9,10.3-10.3c-4-6.5-8.5-12.6-13.2-18.5c-5.2-6.5-13.7,0.8-12.5,7.3c1.5,8.8,8.1,14.9,11.8,22.8
                c4.3-2.5,8.6-5,13-7.6c-1.2-1.7-2.4-3.5-3.6-5.1c-0.4-0.5-3.6-5.2-3.1-3.4c-1.3-4.1-2.8-7.6-5.5-11c-4.7-5.7-15.3,0.4-12.5,7.3
                c3.3,8.3,6.8,17.2,14.4,22.5c3.9,2.7,10,0,11-4.5c1-4.4,0.4-8.6,0.3-13c-0.3-9.6-15.3-9.7-15,0c0.1,2.9,0.9,6.2,0.3,9
                c3.7-1.5,7.3-3,11-4.5c-3.9-2.7-5.9-9.3-7.6-13.5c-4.2,2.4-8.4,4.9-12.5,7.3c1.6,2,1.5,5.3,2.8,7.5c1.9,3.2,4.9,5.9,7,9
                c5.4,8.1,17,1.1,13-7.6c-3.1-6.6-9-11.9-10.2-19.2c-4.2,2.4-8.4,4.9-12.5,7.3c3.9,4.9,7.5,10.1,10.8,15.5
                c3.4-3.4,6.8-6.8,10.3-10.3c-4.9-3.2-5.9-11.2-5.3-16.5c-3.8,2.2-7.5,4.3-11.3,6.5c2.3,1.4,4.2,6.5,4.3,8.5
                c4.7-1.3,9.3-2.5,14-3.8c-0.9-1.9-2-3.7-3.1-5.5c-0.6-0.9-1.3-2-1.7-3c-0.9-2.2-0.6-0.4-0.2-0.9c-3.9,0.5-7.9,1-11.8,1.5
                c0.7,0.6,1.3,2,1.1,0.7C277.7,333.5,292.1,329.5,290.2,320L290.2,320z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#D1D3D4" d="M274,214.5c0.2,0,0.5,0,0.7,0c4.1,0,7.5-3.4,7.5-7.5s-3.4-7.5-7.5-7.5c-0.2,0-0.5,0-0.7,0
                c-4,0-7.7,3.4-7.5,7.5c0.2,4.7,2.4,8.8,5.4,12.3c3.7,4.2,9.3,5.3,14.3,3c8.2-3.9,6.6-16.9-0.9-20.6c-5-2.5-11.1-0.6-13.8,4.4
                c-2.3,4.3-1.6,9.9,2.1,13.3c4.5,4.1,11,4,15.5-0.1c2.5-2.2,3-5.7,2.4-8.8c-0.7-3.5-4.2-5.8-7.4-6.7c-3.9-1.2-8.2,1.3-9.2,5.2
                c-1.1,4,1.3,8.1,5.2,9.2c0.9,0.3,0.7,0.2-0.4-0.2c-3.3-1.8-3.4-6.7-1.2-9.3c1.1-0.8,1.4-1,0.7-0.6c0.4-0.2,0.7-0.3,1.1-0.5
                c-1.3,0.3-0.5,0.3,2.3,0.1c-0.8-0.3-0.5-0.2,0.7,0.3c-0.1-0.1-0.2-0.1-0.3-0.2c0.8,0.4,1.3,1,1.7,1.7c-0.4-0.7-0.3-0.3,0.4,1
                c-0.4-1.2,0.1,2.1,0.1,0.8c-0.3,1.5-0.3,1.9-0.1,1.1c-0.2,0.4-0.4,0.8-0.5,1.2c0.4-0.6,0.1-0.4-0.7,0.8c0.5-0.5,0.2-0.3-0.8,0.6
                c1-0.7-1.9,0.6-0.6,0.3c-1.5,0.4-3,0.2-4.3-0.5c1.1,0.5-1.5-1.3-0.6-0.5c0.5,0.5,0.3,0.3-0.6-0.8c0.2,0.2,0.3,0.5,0.4,0.8
                c-0.5-1.3-0.7-1.7-0.4-1c0.2,0.6,0.2,1.7-0.1-0.8c0.1,1.7,0.6-1.9,0.1-0.3c0.6-1.4,0.8-1.7,0.4-1.1c0.3-0.4,0.6-0.7,0.8-1.1
                c1.2-0.8,1.5-1.1,0.9-0.7c1.4-0.5,1.7-0.7,0.9-0.5c0.4-0.1,0.9-0.1,1.3-0.2c-0.8,0-0.5,0,0.8,0.2c1.2,0.5,1.4,0.6,0.7,0.2
                c1.7,1,0.6,0.5,0.1,0c-0.2-0.2-0.4-0.5-0.6-0.7c1.3,1.5,0.3,0.3,0-0.1c-0.2-0.3-0.3-0.6-0.5-0.9c-0.8-1.5,0.6,1.6,0,0
                c-0.1-0.3-0.2-0.6-0.3-0.9c-0.4-1.5,0.1,1.9,0,0.4C279,209.5,276.5,212,274,214.5c0.2,0,0.5,0,0.7,0c4.1,0,7.5-3.4,7.5-7.5
                s-3.4-7.5-7.5-7.5c-0.2,0-0.5,0-0.7,0c-4.1,0-7.5,3.4-7.5,7.5S269.9,214.5,274,214.5L274,214.5z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#D1D3D4" d="M365.5,395c-0.2-4.8-1.8-9.3-2-14c-3.2,2.4-6.3,4.8-9.5,7.2c6.1,1.3,11,6,16.2,9.2
                c1.3-4.7,2.5-9.3,3.8-14c-2.5-0.3-3.6-2.1-4.4-4.3c-1.6-4-2.9-8.2-5.1-12c-4.3,2.5-8.6,5-13,7.6c4.1,6.9,7.9,14.1,14.7,18.7
                c7,4.7,15.8-6.5,9.1-11.8c-2.6-2-4.1-4.7-5.8-7.5c-4.5-6.9-16.5-2.6-13.7,5.8c2.3,7,7.8,12.7,9,20c1.6,9.5,16.1,5.5,14.5-4
                c-1.3-7.6-6.7-12.9-9-20c-4.6,1.9-9.1,3.9-13.7,5.8c2.4,3.7,4.6,7.8,8.2,10.5c3-3.9,6.1-7.9,9.1-11.8c-4.3-2.9-6.7-9-9.3-13.3
                c-4.9-8.3-17.9-0.8-13,7.6c2.6,4.4,3.6,9.5,6.1,14c3.4,6.3,9.6,8.9,16.4,9.7c7.9,1,9.6-10.4,3.8-14c-6.4-4-12.3-9.1-19.8-10.8
                c-4.6-1-9.7,2.1-9.5,7.2c0.2,4.8,1.8,9.3,2,14C350.9,404.6,365.9,404.7,365.5,395L365.5,395z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#E6E7E8" d="M196,382.8c-5.7-2.2-13.9,0.8-16.5,6.4c-2.3,5,0.3,11.3,6.5,11.3c5,0,10-0.8,13.1-5.2
                c2.8-3.9,2.8-8.4-0.3-12.1c-5.3-6.4-16.1-2.7-20.2,2.9c-5.9,8,1.3,19.8,11.1,16.7c6.3-1.9,12.6-8.6,14.5-14.8
                c1.4-4.7-2.5-9.5-7.2-9.5c-1.7,0-3.3,0-5,0c-3.3,0-6.3,2.3-7.2,5.5c-1,3.6,0.7,6.4,3.4,8.5c2,1.5,4.4,2,6.8,2
                c6.1,0.1,10.5-8.6,5.3-12.8c-5.6-4.6-12.1-2.5-17.4,1.5c-4.9,3.7-9.9,8.4-10.4,14.8c-0.4,4.5,3.7,7,7.5,7.5
                c6.2,0.8,10.6-3.7,11.2-9.5c0.7-5.9-6-10.2-11.2-9.5c-5,0.7-8.3,4.3-7.2,9.5c1.2,5.9,7,7.5,12.2,7.5c9.7,0,9.7-15,0-15
                c-0.5,0-1.1,0-1.6,0c0.3,0,0.6,0.1,0.9,0.2c0.9,0.9,1.8,1.8,2.7,2.7c0.1,0.2,0.2,0.4,0.3,0.6c-2.4,3.2-4.8,6.3-7.2,9.5
                c0.3,0,0.5-0.1,0.8-0.1c-2.4-0.3-4-2.1-4.5-5.4c1.3-1.8,2.6-3.5,3.8-5.3c1-0.1,0.9-0.2-0.1-0.2c2.5,2.5,5,5,7.5,7.5
                c0,0.2-1,3.1-0.4,1.8c0.1-0.3,1.2-1.7,0.2-0.4c1-1.3,2.3-2.4,3.5-3.5c1.8-1.5-1.1,0.7,0.8-0.6c0.6-0.4,1.2-0.7,1.8-1.1
                c1.8-1-1.3,0.4,0.4-0.2c0.9-0.2,0.7-0.2-0.6,0c1.8,0,0.7-0.5-3.5-1.7c1.8-4.3,3.5-8.5,5.3-12.8c0.3,0.2,0.5,0.2,0.8,0
                c-1.3,4.7-2.5,9.3-3.8,14c1.7,0,3.3,0,5,0c-2.4-3.2-4.8-6.3-7.2-9.5c-0.4,1.4,0.9-1.7,0.1-0.2c-0.3,0.5-0.6,1-0.9,1.4
                c1-1.4-0.3,0.3-0.5,0.5c-0.5,0.5-0.9,1-1.4,1.5c-0.1,0.1-1.9,1.5-0.5,0.5c-0.5,0.4-1,0.7-1.5,1c1.5-0.9-0.7-0.2,1.5-0.3
                c1.9-0.1,4.4,1.5,4.9,3.3c0,0.7,0,1.4,0,2.2c-0.5,0.9-0.5,1-0.1,0.3c-0.2,0.3-1.9,1.7-0.7,0.9c1.5-0.9-1.5,0.4,0.2,0
                c1.7-0.5-1.7,0.1,0,0c1.3,0-1.5-0.5-1.7-0.6c-2.5-1.1-3.4-5.3-2.2-7.6c-1,1.9,3.8-1.9,1-1.3c0.8-0.2-1.6,0-1.9,0
                c2.2,3.8,4.3,7.5,6.5,11.3c0.6-1.1,0.3-0.9-0.8,0.5c-0.6,0.1-0.5,0.1,0.4-0.1c-0.7,0-0.7,0,0.2,0c-0.9-0.2-1-0.1-0.2,0.1
                c3.8,1.5,8.2-1.6,9.2-5.2C202.4,387.8,199.8,384.2,196,382.8L196,382.8z"/>
        </g>
    </g>
</g>
<g id="Layer_8">
    <g>
        <g>
            <path fill="#4CA9DE" d="M276,219.5c4.8-0.1,10-2.3,12.5-6.7c1.6-3,1.3-6.7-1.2-9.1c-2.5-2.5-6-2.7-9.1-1.2
                c-0.4,0.2-0.8,0.4-1.2,0.7c-1.6,1-2.1,1.7-3.2,3.1c-1.2,1.5-1.3,3.8-1.3,5.7c0.7-1.8,1.5-3.5,2.2-5.3c-0.3,0.3-0.7,0.7-1,1
                c-1.7,1.7-2.2,4-2.2,6.3c0,4.1,3.4,7.5,7.5,7.5c0.3,0,0.7,0,1,0c4.1,0,7.5-3.4,7.5-7.5c0-0.3,0-0.7,0-1c0-4.1-3.4-7.5-7.5-7.5
                c-3,0-5.2,1.1-7.3,3.2c-2.9,2.9-2.9,7.7,0,10.6c2.9,2.9,7.7,2.9,10.6,0c0.2-0.2,0.5-0.5,0.7-0.7c-0.5,0.4-1,0.8-1.5,1.2
                c0.1-0.1,0.2-0.2,0.3-0.2c-0.6,0.3-1.2,0.5-1.8,0.8c0.1,0,0.1,0,0.2-0.1c-0.7,0.1-1.3,0.2-2,0.3c0.2,0,0.5,0,0.7,0
                c-2.5-2.5-5-5-7.5-7.5c0,0.3,0,0.7,0,1c2.5-2.5,5-5,7.5-7.5c-0.3,0-0.7,0-1,0c2.5,2.5,5,5,7.5,7.5c0-0.3,0-0.7,0-1
                c-0.7,1.8-1.5,3.5-2.2,5.3c0.3-0.3,0.6-0.6,1-1c1.4-1.4,2.2-3.3,2.2-5.3c0-0.4,0-0.8,0-1.3c-0.1,0.7-0.2,1.3-0.3,2
                c0-0.2,0.1-0.3,0.1-0.5c-0.3,0.6-0.5,1.2-0.8,1.8c0-0.1,0.1-0.2,0.2-0.3c-0.4,0.5-0.8,1-1.2,1.5c0.1-0.1,0.2-0.2,0.2-0.2
                c-0.5,0.4-1,0.8-1.5,1.2c0.4-0.3,0.9-0.5,1.4-0.8c-3.4-3.4-6.8-6.8-10.3-10.3c-0.1,0.2-0.2,0.3-0.3,0.5c0.4-0.5,0.8-1,1.2-1.5
                c-0.3,0.3-0.5,0.6-0.8,0.9c0.5-0.4,1-0.8,1.5-1.2c-0.5,0.4-1,0.7-1.6,1c0.6-0.3,1.2-0.5,1.8-0.8c-0.8,0.3-1.6,0.5-2.4,0.6
                c0.7-0.1,1.3-0.2,2-0.3c-0.3,0-0.5,0-0.8,0.1c-4.1,0.1-7.5,3.3-7.5,7.5C268.5,216,271.9,219.6,276,219.5L276,219.5z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#004F70" d="M228.5,224c0,0.3,0,0.6,0,0.9c1.8-2.4,3.7-4.8,5.5-7.2c0,0-0.1,0-0.1,0c1.9,0.3,3.9,0.5,5.8,0.8
                c-6.6-3.8-11.4,2-13.3,7.7c-1.9,5.8,0.6,13.2,5.9,16.3c11.6,6.8,20.6-7.2,18.5-17.6c-1.1-5.6-5.2-10.1-11-10.8
                c-5.1-0.6-9,2.9-11.8,6.7c-7.3,10,2.4,23.5,13.9,18.4c8.5-3.8,11.4-18.2,4.1-24.2c-8-6.6-21.5-3.3-25.1,6.4
                c-4.7,12.9,17.1,18.8,22,6.5c4.8-12-9.8-17.3-18.8-17.5c0,5,0,10,0,15c0.7,0,1.3,0,2,0c3.2,0,6.5-2.3,7.2-5.5
                c1.3-6-1.4-10.4-6.4-13.5c-1.3,4.7-2.5,9.3-3.8,14c5.2,0.1,10.3,0.9,15.5,1.5c4.7,0.6,9.3,0.1,14,0.4c0.4,0,0.5,0.3,0.5,0.2
                c0.4,0.8,1.2,3,1.8,4.2c2.1,4.4,6.6,6.2,11.2,6.6c8,0.7,9.5-10.2,3.8-14c-3.1-2.1-5.1-5.9-5.3-9.5c-0.4-7.9-9.6-9.6-14-3.8
                c-4,5.4-1.2,13.2,1,18.7c1.2,3,2.5,6,3.8,8.9c0.7,1.6,1.9,3,3.1,4.3c0.9,0.9,2,1.6,3.3,1.9c0.6,0.2,4.1,1.2,2.1,0.4
                c-1.7-1.7-3.5-3.5-5.2-5.2c-0.1,0-0.1,0.1-0.2,0.1c0.3-1.3,0.7-2.5,1-3.8c-1.1,2.6-3.1,5.2-4.4,7.9c-0.8,1.6-1.7,3.1-2.7,4.6
                c-0.3,0.4-0.7,0.9-1.1,1.2c1-1.1,1-0.2-0.3-0.6c-2.8-0.8-5.6-4.5-8-6.1c-3.6-2.6-7.5-4.6-10.6-7.8c-0.7-0.7-1.1-1.8-1.4-2.7
                c-0.7-2.1,0.5-1.3-1.1-0.1c2.3-1.6,8.8,4.1,9.2,4.9c1.3,2.5,1.9,5.6,3.7,8c2.6,3.6,6,5.3,10.4,5.7c3.5,0.3,6.2-2.6,7.2-5.5
                c1.9-5.4-1-11.5-6.6-13.1c-6.1-1.7-11.1,2.6-12.9,8.1c-1.2,3.9,1.4,8.1,5.2,9.2c14.3,4,22.3-19.7,9.7-26
                c-7-3.4-15.3,2.3-15.3,9.9c0,4.1,2.6,7.1,5.8,9.1c3.7,2.3,8.1,0.9,11.6-0.8c8.7-4.2,1.1-17.1-7.6-13c-0.4,0.2-0.8,0.3-1.2,0.2
                c1.3,0,2.7,0,4,0c0.7,0.8,1.4,1.6,2.1,2.4c0,1.3,0,2.7,0,4c-1.1,0.6-2.2,1.3-3.2,2.1c-1.3-0.3-2.5-0.7-3.8-1
                c-2.7-1.3-0.9-3.8-1.3-1.4c-0.4,2.9-0.2-1,3.2-0.1c1.7,3.1,3.5,6.2,5.2,9.2c-1.2,0.9-2.4,1.8-3.6,2.6c-1.3-0.3-2.5-0.7-3.8-1
                c-0.7-1.5-1.4-3.1-2.1-4.6c2.4-1.8,4.8-3.7,7.2-5.5c2.4,0.2,2.3,1.9,1.1-0.7c-0.4-0.9-0.7-1.9-1.1-2.9c-1.5-3.7-3.3-6.1-6.6-8.5
                c-7-5.2-17.1-9.4-25.2-3.8c-6.7,4.7-6.6,13.6-3.3,20.4c3.8,7.7,11.5,10.7,17.7,15.9c6,5,12.9,10.2,21.2,7.1
                c6.8-2.6,10.3-10.1,13.3-16.3c1.7-3.5,4.4-6.3,3.2-10.6c-0.6-2.2-1.6-4.1-3.7-5.3c-0.8-0.4-1.6-0.7-2.3-1
                c-3.3-0.7-2.7-0.1,1.7,1.6c-1.5-2-2.3-5.1-3.3-7.5c-0.6-1.5-1.1-3-1.6-4.5c-0.2-0.7-0.4-1.5-0.5-2.2c-0.1-0.2-0.1,0.6,0,0.6
                c-4.7-1.3-9.3-2.5-14-3.8c0.5,9.2,5.1,17.3,12.7,22.5c1.3-4.7,2.5-9.3,3.8-14c1.6,0.1,2.1,1.8,1.4,0c-0.8-2.2-1.6-3.9-3-5.7
                c-2.9-3.7-7.4-4.9-11.9-5.4c-4.1-0.5-8.3,0.2-12.4-0.2c-5.7-0.5-11.4-1.6-17.1-1.7c-7.6-0.1-10.1,10.2-3.8,14
                c-0.1-0.2-0.1-0.4-0.2-0.7c0-1.3,0-2.7,0-4c-0.1,0.4-0.2,0.8-0.3,1.2c2.4-1.8,4.8-3.7,7.2-5.5c-0.7,0-1.3,0-2,0
                c-9.7,0-9.7,14.9,0,15c1.7,0,2.9,0.1,4.4,0.5c-2.5-0.6,1.6,0.7,0.9,0.2c-3.2-2.6,3.3-8.6,5.1-5c0.3,1.3,0.7,2.5,1,3.8
                c0.1,1.4-2.5,2.4-0.5,1.8c1.1-0.3,1.4-0.2,2.3,0.1c-0.6-0.3-1.2-0.6-1.7-1.1c-0.6-1.1,0.1-2.8-0.4-0.9c0,0.1-0.4,1-0.4,0.9
                c-2.2,2.8,2.2-5.9,5.7,2.7c0.3,0.9-0.2,0.7,0.4,0c-1.4,1.6-4.8-1.2-4.7-0.6c0,0.5-0.1,1.9-0.3,2.2c0.4-0.5,4.7-2,4.9,0.4
                c-0.1-1.4,1.6-0.9-1.4,0.7c-2.5,0-5,0-7.6,0c2.1,1.2,3.9,1.2,6.2,0.9c3.3-0.5,5.5-4.2,5.5-7.2c0-0.3,0-0.6,0-0.9
                C243.7,214.3,228.7,214.3,228.5,224L228.5,224z"/>
        </g>
    </g>
</g>
<g id="Layer_9">
    <g>
        <g>
            <path fill="#F26236" d="M274.7,318.7c-8.4,6.5-9.4,21.4,3.3,22.8c2.7,0.3,5-1.7,6.5-3.7c4-5.6,1.6-11.7-3.7-15.3
                c-4-2.7-9.9,0-11,4.5c-1.3,5.6-0.2,10.9,2.8,15.8c4.4,7.3,16,2.5,13.7-5.8c-1.3-4.5-3.2-8.8-5.8-12.8c-4.7-7.3-15.5-2.5-13.7,5.8
                c1.7,7.9,4.4,16.3,9.9,22.3c2.6,2.9,8,3,10.6,0c3.3-3.7,5.7-8.2,6.1-13.1c0.2-2.3-0.2-4.4-1.6-6.3c-0.5-0.8-1.1-1.5-1.8-2.1
                c-0.5-0.4-1-0.8-1.5-1.2c-1.3-1.1-1.1-0.7,0.7,1.4c-2.3-9.4-16.7-5.4-14.5,4c0.7,2.9,2.5,4.7,4.7,6.4c2.4,1.8-0.3-5.4-1.5-1.8
                c-0.2,0.7-0.8,1.5-1.3,2.1c3.5,0,7.1,0,10.6,0c-3.6-4-5-10.6-6.1-15.7c-4.6,1.9-9.1,3.9-13.7,5.8c1.9,2.9,3.3,5.9,4.2,9.2
                c4.6-1.9,9.1-3.9,13.7-5.8c-0.8-1.3-1.6-2.7-1.2-4.2c-3.7,1.5-7.3,3-11,4.5c0.1,0.1,0.2,0.3,0.3,0.4c-0.6-1.1-1.3-2.2-1.9-3.3
                c0.1,0.3,0.2,0.7,0.3,1c0-1.3,0-2.7,0-4c-0.1,0.2-0.2,0.4-0.3,0.5c2.2-1.2,4.3-2.5,6.5-3.7c0.2,0,0.3-0.1,0.5-0.1
                c1.1,0.6,2.2,1.3,3.3,1.9c0.5,4.5,0.9,5.6,1.4,3.4c-0.1,0.4,0,0.1,0.4-0.8c0.4-0.8,0.8-1,1.6-1.7c3.2-2.5,2.6-8,0-10.6
                C282.1,315.5,277.9,316.2,274.7,318.7L274.7,318.7z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#F26236" d="M419,483.5c0.2,0,0.5,0,0.8,0c9.7,0,9.7-15,0-15c-6.6,0-10.3,7.4-13.2,12.5c-1.5,2.5-2.6,5.3-3.7,7.9
                c-0.7,1.7-0.9,4.7-2.1,5.8c-7.4,6.3,3.3,16.9,10.6,10.6c3.7-3.2,4.5-8,6-12.4c1.6-4.7,5.2-9.1,8.2-13.1
                C423.3,481,421.2,482.3,419,483.5c0.2,0,0.5,0,0.8,0c9.7,0,9.7-15,0-15c-0.2,0-0.5,0-0.8,0C409.3,468.5,409.3,483.5,419,483.5
                L419,483.5z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#F26236" d="M436,456.2c0.3,0.1,0.6,0.1,0.9,0.2c-1.5-1.1-3-2.3-4.5-3.4c-0.8-1.3,0.5,2.3,0.4,2
                c4.6-1.9,9.1-3.9,13.7-5.8c-1.1-2.3-1.9-4.7-3-7c-3.6-7.8-16.2-2.2-13.7,5.8c2.7,8.9,9.3,16.2,12,25c0.9,3.1,3.9,5.7,7.2,5.5
                c5.1-0.3,8.2-3.4,8.5-8.5c0.5-9.7-14.5-9.6-15,0c0,0.2,0,0.5,0,0.7c0.7-1.8,1.5-3.5,2.2-5.3c-0.1,0.1-0.2,0.2-0.3,0.3
                c1.8-0.7,3.5-1.5,5.3-2.2c-0.2,0-0.5,0-0.7,0c2.4,1.8,4.8,3.7,7.2,5.5c-1.2-4.1-3.4-7.8-5.3-11.6c-2.2-4.3-5.3-8.8-6.7-13.4
                c-4.6,1.9-9.1,3.9-13.7,5.8c1.1,2.3,1.9,4.7,3,7c3.6,7.7,16.4,2.2,13.7-5.8c-1.5-4.3-2-7.9-7.2-9.2
                C430.6,439.4,426.6,453.8,436,456.2L436,456.2z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#B94B27" d="M427.5,448c0.3,5.8,5.4,11.4,8.2,16.3c3.1,5.3,3.7,10.7,10,13.2c-1.5-1.1-3-2.3-4.5-3.4
                c0.4,0.6,0.8,1.2,1.3,1.7c2.5,3.2,6.4,5,10.3,2.7c3.2-1.9,5.2-7,2.7-10.3c-2-2.6-3.4-4.2-6.5-5.4c1.1,0.6,2.2,1.3,3.3,1.9
                c-2.1-2.5-2.8-6.6-4.5-9.5c-1.9-3.3-5.1-7.9-6.3-10.3c0.3,1.3,0.7,2.5,1,3.8c0-0.2,0-0.5,0-0.7C442,438.4,427,438.3,427.5,448
                L427.5,448z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#BCBEC0" d="M401,418.5c-1.6-0.1-2-0.1-1.2,0c-2.8-1.8-6.8-1.2-9.1,1.2c-3.2,3.2-2.5,7.4,0,10.6
                c3.1,4,7.6,6.2,12.3,7.9c6.8,2.4,11-5.7,8.5-11c-2-4.1-5.3-7-8.9-9.8c-3.9-3-7.1-6.6-10.1-10.5c-6.3-8.2-7.8-18.9-14.2-27.2
                c-4.6-5.9-15,0.5-12.5,7.3c1.2,3.3,2.7,6.5,4.4,9.5c1.7,2.9,4,5.8,5.1,9.1c2.1,6.3,4,12.5,8.4,17.7c4.3,5.1,9.8,8.9,15,13
                c6.1,4.8,12.5,9.3,20.3,10.9c9.6,2,13-11.1,4-14.5c-10.9-4-26.4-4.2-32.5-15.6c-4.6-8.5-17.5-1-13,7.6
                c8.6,16,26.3,16.8,41.5,22.4c1.3-4.8,2.7-9.6,4-14.5c-5.7-1.2-10.4-4.5-15-8.1c-4.4-3.5-8.9-6.7-12.7-10.8
                c-3.5-3.8-4.6-9.2-6.1-13.9c-2-6.1-6.8-11-9-17c-4.2,2.4-8.4,4.9-12.5,7.3c5.5,7.1,6.3,16.9,11.8,24.1c3.1,4.1,6.2,8.1,10,11.6
                c3.1,2.9,7.1,4.7,9,8.7c2.8-3.7,5.6-7.3,8.5-11c-2.4-0.9-4.1-2.1-5.7-4.1c-3,3.9-6.1,7.9-9.1,11.8c2.7,1.7,5.6,2,8.8,2
                C410.7,433.6,410.7,418.6,401,418.5L401,418.5z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#BCBEC0" d="M408.8,440c2.4-1.4,4.8-2.7,7.2-4.1c-0.7-0.2-0.7-0.2,0.2,0.2c-0.9-0.4-1.7-1-2.6-1.5
                c-1.6-1-2.9-2.1-4.4-3.3c-3.3-2.7-6.4-5.7-9.8-8.3c-10.9-8.4-14.5-22.9-18.3-36c-2.7-9.3-17.2-5.3-14.5,4
                c2.9,10.1,5.6,21.1,11.2,30.1c2.6,4.2,6,7.9,9.5,11.2c1.3,1.2,2.7,2.4,4.2,3.5c-2.1-1.5,0.6,0.6,1,1c4.5,3.9,9,8,14.1,11.3
                c5.7,3.7,14.4,4,16.5-4C425.7,434.6,411.2,430.7,408.8,440L408.8,440z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#BCBEC0" d="M288.5,295.8c0.1,0.3,0.2,0.6,0.4,0.8c-0.3-1.3-0.7-2.5-1-3.8c-0.1-1.9-0.1-0.3-0.9,0.9
                c-0.7,0.9-1.3,1.8-1.9,2.8c-1.1,1.8-1.4,4-2.5,5.7c-5.4,8.2,7.4,15.3,13,7.6c6-8.3,13-26.3-0.1-31.9c-3-1.3-6.2-1.6-9.4-1.8
                c-1.9-0.2-4.1-1.6-6.1-2.1c-7-1.6-13.3,2.1-15.1,9.1c-2,8,10.1,13.8,13.7,5.8c1-2.2,1.6-4.5,2.6-6.6c0.4-0.8-0.7,0.4-1,0.3
                c1.1,0.5,2,1.4,3,2.2c2.1,1.9,4.3,4,5.9,6.3c2.1,3,1,5.2-1.3,7.7c-5.9,6.4,1.5,15.6,9.1,11.8c13.6-6.9,12-27.1-0.6-34.3
                c-4.4-2.5-8.8-5.2-13.7-6.6c-7.2-2.1-13.4,2-14,9.4c-0.8,9.6,14.2,9.6,15,0c-1.2,4-3.2,5.7-5.8,4.9c0.5,0.1,1,0.2,1.5,0.4
                c1,0.3,1.6,0.6,2.4,1c2.2,1.1,4.4,2.3,6.5,3.6c1,0.6,2.4,1.9,3.2,4.3c0.7,2.3-0.2,3.3-2.1,4.3c3,3.9,6.1,7.9,9.1,11.8
                c4.7-5.1,8.1-11.4,6.6-18.5c-1.4-6.9-6.3-12-11.2-16.7c-4.9-4.7-11.1-8-17.9-5.9c-6.4,1.9-7.8,7.7-10.2,13.1
                c4.6,1.9,9.1,3.9,13.7,5.8c1.7-6.8-2.7,1.5-2.3,1.6c1.4,0.3,2.6,1.2,4.1,1.6c2.3,0.6,5.7,0.1,7.8,1c-1-0.4-1-2.6-1-0.2
                c0,1.3-0.7,2.8-1.1,4c-0.9,2.6-2.6,5-4.1,7.2c4.3,2.5,8.6,5,13,7.6c2.1-3.1,2.3-5.7,4.4-8.5c3.1-4.2,3.6-8.3,1.6-13
                c-1.6-3.8-7.1-4.6-10.3-2.7C287.4,287.8,286.9,292,288.5,295.8L288.5,295.8z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#E6E7E8" d="M308.7,402.3c-0.1-0.1-0.2-0.3-0.3-0.4c2.5,3.5,8.6,5.6,11.8,1.5c1.6-2.1,2.3-3.8,2.3-6.4
                c0.1-4.1-3.5-7.5-7.5-7.5c-0.3,0-0.7,0-1,0c2.5,2.5,5,5,7.5,7.5c0-0.3,0-0.7,0-1c-4.7,1.3-9.3,2.5-14,3.8c2.5,4.2,6.1,7.1,9.9,10
                c4.5,3.3,7.9,7.7,11.5,12c7.3,8.9,14.6,17.5,22.4,25.9c7.7,8.2,16.5,16.2,27.3,19.8c5.1,1.7,10.2,3.2,15.5,4.3
                c3.6,0.8,7.9,1,11.1,2.8c4.5,2.5,12-0.6,11.3-6.5c-0.3-2.2-0.7-3.9-2-5.8c-2.4-3.3-6.5-4.9-10.3-2.7c-3.3,1.9-5.1,7-2.7,10.3
                c0-0.6,0-1.2,0-1.8c3.8-2.2,7.5-4.3,11.3-6.5c-6.9-3.9-15.7-4.2-23.2-6.4c-5.3-1.5-10-3.1-14.5-6.2c-4.4-3-8.3-6.9-12-10.8
                c-7.5-7.8-14.6-15.9-21.3-24.4c-3.3-4.1-6.6-8.1-10.2-11.9c-3.3-3.5-8.5-5.4-11-9.7c-3.8-6.4-14-3.7-14,3.8c0,0.3,0,0.7,0,1
                c0,4.1,3.4,7.5,7.5,7.5c0.3,0,0.7,0,1,0c-2.5-2.5-5-5-7.5-7.5c0,0.3,0,0.7,0,1c0.7-1.8,1.5-3.5,2.2-5.3c0,0-0.1,0.1-0.1,0.1
                c3.9,0.5,7.9,1,11.8,1.5c-0.6-0.9-1.3-1.8-2-2.6c-2.6-3.2-7.9-2.7-10.6,0C305.6,394.8,306.1,399.1,308.7,402.3L308.7,402.3z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#E6E7E8" d="M197,317.2c3.9,0.8,7.6,2,11.5,3.1c4.4,1.2,8.8,1.2,13.2,1.3c8,0.2,15.9,0.7,23.9-0.4
                c14.9-1.9,29.9-9.1,38.9-21.4c3.5-4.7-1.3-11.3-6.5-11.3c-0.7,0-1.3,0-2,0c-9.7,0-9.7,15,0,15c0.7,0,1.3,0,2,0
                c-2.2-3.8-4.3-7.5-6.5-11.3c-6.1,8.3-18.5,13.3-28.5,14.3c-7.1,0.7-14.2,0.3-21.3,0.1c-3.2-0.1-6.1,0-9.2-0.8
                c-3.9-0.9-7.6-2.3-11.5-3.1C191.5,300.9,187.5,315.4,197,317.2L197,317.2z"/>
        </g>
    </g>
    <path fill="#E6E7E8" d="M260.8,163c-1.1,0.2-2.2,0.3-3.3,0.6c-5.3,1.3-10.6,3.2-15.3,5.9c-4.9,2.7-9,7.4-12.6,11.6
        c-4,4.7-6.7,9.7-7.1,15.9c-0.7,9.7,14.3,9.6,15,0c0.3-4.1,5.1-8,7.8-10.7c3.8-3.8,7.6-5.6,12.7-7.2c6.7-2.1,16.6-1.1,19.2-9.1
        c0.4-1.2,0.5-2.3,0.4-3.3C272.1,165.1,266.5,163.9,260.8,163z"/>
    <path fill="#E6E7E8" d="M276.1,163.4c-0.7-0.2-1.5-0.3-2.2-0.5c-1.9-0.3-3.8-0.5-5.7-0.6c2.9,0.6,5.8,1.3,8.6,2.1
        C276.6,164,276.3,163.7,276.1,163.4z"/>
    <g>
        <g>
            <path fill="#E6E7E8" d="M309,262.5c5.8-0.7,9.1-3.7,13.9-6.6c5-3,10.3-2.6,15.6-1.3c10,2.4,16.3,16.3,20.8,24.3
                c7,12.2,15.3,24.3,20.3,37.5c2.2,5.8,5.7,12.3,6.5,18.5c0.8,5.8,1.2,11.9,4.4,16.9c2.2-3.8,4.3-7.5,6.5-11.3c-0.2,0-0.5,0-0.7,0
                c1.3,0.3,2.5,0.7,3.8,1c0.1,0.1,0.2,0.1,0.3,0.2c-7-6.7-17.6,3.9-10.6,10.6c2.2,2.1,4.2,3,7.3,3.2c5.6,0.3,9.5-6.6,6.5-11.3
                c-2.7-4.2-2.4-10.4-3.2-15.2c-0.7-3.7-2.8-7.6-4.2-11.1c-1.4-3.7-2.8-7.4-4.2-11.1c-2.7-7.2-7.2-13.8-11-20.4
                c-3.8-6.7-7.7-13.4-11.5-20.2c-6.1-10.6-13.7-21.2-25.5-25.7c-6.3-2.4-13.1-2.4-19.6-1.1c-3.1,0.7-6.1,2.2-9,3.5
                c-1.9,0.8-4.9,4.4-6.3,4.6c-4.1,0.5-7.5,3.1-7.5,7.5C301.5,258.7,304.9,263,309,262.5L309,262.5z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#A7A9AC" d="M364.5,379c0.4,6.2,3.9,10.7,6.3,16.3c1,2.3,4.9,1.1,4.7-1.3c-0.5-5.9-4.6-10.1-6.1-15.7
                c-0.8-3.2-5.4-1.8-4.8,1.3c1.1,5.7,4,10.5,6.9,15.4c3.8,6.3,7.1,12.7,11.1,18.9c3.4,5.2,6.9,10.1,10.8,14.9
                c4.3,5.2,8.6,10.4,11.1,16.8c0.8-1.1,1.6-2.1,2.4-3.2c-11.3-0.7-20.1-15.1-25.3-23.7c-7.9-13.2-14-27.2-14.2-42.8
                c-0.8,0.8-1.7,1.7-2.5,2.5c3.6,0.4,6.3,8.4,7.5,11.4c2.8,6.6,5.8,13.1,9.3,19.4c3.4,6.1,7.6,12.1,11.9,17.6
                c3.9,5,8.5,9.6,11.1,15.4c0.9-1.2,1.9-2.4,2.8-3.7c-7.7-2-12.1-8.8-17.1-14.5c-5.4-6.1-13.4-11.3-15.1-19.8
                c-1.4,0.8-2.8,1.6-4.2,2.4c3.5,2.9,5.9,6.7,9,10c1.9,2.1,5.6-0.7,3.9-3c-4.1-6-9.4-11.4-11.7-18.4c-1.5,0.6-3,1.3-4.6,1.9
                c2.8,4.5,6.5,8.4,9,13c1.6,2.9,5.6,0.4,4.3-2.5c-2.1-5-5.2-9.4-8-14c-1.3,1-2.6,2-3.9,3c7.5,8.5,14,17.7,20.9,26.6
                c3.9,5,7.9,9.9,11.9,14.9c3,3.7,7.3,5.8,10.2,9.5c2,2.5,5.5-1,3.5-3.5c-2.5-3.2-6.5-4.8-9-8c-4-5-8-10-12-15
                c-7.4-9.3-14.1-19.1-22-28c-1.9-2.2-5.4,0.6-3.9,3c2.8,4.6,5.9,9,8,14c1.4-0.8,2.9-1.7,4.3-2.5c-2.5-4.6-6.2-8.5-9-13
                c-1.5-2.3-5.5-0.9-4.6,1.9c2.5,7.5,7.8,13.2,12.3,19.6c1.3-1,2.6-2,3.9-3c-3.1-3.3-5.5-7.1-9-10c-1.9-1.6-4.7-0.1-4.2,2.4
                c1.9,9.3,10.3,15.4,16.4,22c5.8,6.3,10.6,13.4,19.3,15.8c2.3,0.6,3.7-1.7,2.8-3.7c-3.1-6.9-8.9-12.3-13.3-18.3
                c-4.9-6.6-9.4-13.8-13.1-21.2c-3.1-6.1-5.7-12.5-8.7-18.7c-1.9-4-4.4-7.6-9-8.1c-1.3-0.1-2.5,1.2-2.5,2.5
                c0.2,17.1,7.1,32.3,15.8,46.8c6,10,15.8,24,28.7,24.7c1.5,0.1,3-1.7,2.4-3.2c-4.3-10.9-13.1-18.7-19.4-28.3
                c-4.2-6.3-8.3-12.6-11.8-19.3c-3.1-6.1-7.5-11.5-8.7-18.4c-1.6,0.4-3.2,0.9-4.8,1.3c1.3,5,5.5,9.2,5.9,14.3
                c1.6-0.4,3.1-0.8,4.7-1.3c-2-4.6-5.4-8.6-5.7-13.7C369.3,375.8,364.3,375.8,364.5,379L364.5,379z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#A7A9AC" d="M271,285.5c0.2,0,0.5,0,0.8,0c3.2,0,3.2-5,0-5c-3,0-3.8,2.7-5.5,4.7c1.4,0.8,2.8,1.6,4.2,2.4
                c0.4-2.8,2.6-4.8,3.7-7.4c0.9-2-1.2-5.1-3.4-3.4c-3.4,2.6-5.6,5.5-7.1,9.5c-1,2.5,2.5,4,4.2,2.4c4.2-3.8,5-13.1,10.9-14.4
                c-1-1-2.1-2.1-3.1-3.1c-1.3,4.3-3.7,8.1-4.1,12.7c-0.3,3.2,4.6,3.1,5,0c1.1-10,22.7-12.8,24-2c0.4,3.2,5.4,3.2,5,0
                c-2.1-17.4-32.1-14.4-34,2c1.7,0,3.3,0,5,0c0.4-4,2.7-7.5,3.9-11.3c0.6-1.9-1.3-3.5-3.1-3.1c-8.1,1.8-7.8,10.8-13.1,15.6
                c1.4,0.8,2.8,1.6,4.2,2.4c1-2.7,2.5-4.8,4.9-6.5c-1.1-1.1-2.3-2.3-3.4-3.4c-1.3,3-3.8,5.2-4.3,8.6c-0.3,2.2,2.4,4.6,4.2,2.4
                c0.2-0.2,2.5-3.3,2-3.3c3.2,0,3.2-5,0-5c-0.2,0-0.5,0-0.8,0C267.8,280.5,267.8,285.5,271,285.5L271,285.5z"/>
        </g>
    </g>
    <g>
        <g>
            <path fill="#A7A9AC" d="M274.5,283c0,0.4,0,0.8,0,1.2c0,3.2,5,3.2,5,0c0-0.1,0-0.1,0-0.2c-0.4,0.7-0.8,1.4-1.2,2.2
                c2.6-1.2,4.3-3.4,6.6-5c2.8-1.9,8.2-1.1,11.2-0.4c7.6,1.9,9.1,13.6,9.4,20.2c0.1,3.2,5.1,3.2,5,0c-0.2-7.4-2.5-14.2-8.3-19.1
                c-6.5-5.5-15.1-4.6-22.9-3.3c0.9,1.2,1.9,2.4,2.8,3.7c2.7-4.5,7.4-4.4,11.6-2.1c1.7,0.9,6.4-0.1,6.9,2.5c0.6,3.2,5.4,1.8,4.8-1.3
                c-1-5.4-7.5-4.9-11.4-6.6c-6.2-2.7-12.8-0.8-16.2,5c-1.2,2,0.8,4,2.8,3.7c6.8-1.1,13.5-1.8,19.1,2.9c4.3,3.6,5.6,9.3,5.7,14.7
                c1.7,0,3.3,0,5,0c-0.3-8.8-2.4-20.4-11.3-24.3c-4.1-1.8-8.9-1.7-13.2-1.2c-4.3,0.5-6.6,4.7-10.3,6.3c-0.8,0.4-1.2,1.4-1.2,2.2
                c0,0.1,0,0.1,0,0.2c0,3.2,5,3.2,5,0c0-0.4,0-0.8,0-1.2C279.5,279.8,274.5,279.8,274.5,283L274.5,283z"/>
        </g>
    </g>
</g>
<g id="Layer_2">
    <path d="M296.6,173.9c-9-8.8-22.9-14.2-35.7-12.8c0,0,0-0.1-0.1-0.1c-0.7,0.1-1.4,0.2-2.1,0.3c-1,0.2-2.1,0.4-3.1,0.5
        c-10.5,2.1-20.4,7.1-28,14.9c-1.3,1.3-2.6,2.6-3.9,4c-0.6,0.8-1.2,1.5-1.8,2.3c-0.6,1-1.2,1.9-1.7,3c-7.2,13.5-9.5,30-6.2,44.9
        c4.1,18.7,20.7,28.1,38.3,30.4c0.4,0,0.9,0.1,1.3,0.2c0.1,0,0.2,0,0.3,0c4,0.4,8.1,0.5,12,0.2c25.2-1.8,47.1-17,50.5-43.1
        C318.3,202.8,307.5,184.6,296.6,173.9z M308.5,222.5c-4.2,21.4-25.8,31.4-45.7,32.4c-3.8,0.2-7.7,0-11.4-0.6c-0.1,0-0.2,0-0.3,0
        c-13.5-2.2-25.9-9.6-30-23.7c-3.7-12.7-1.2-27.1,4.2-39c5.4-11.9,14.1-18,24.9-22.4c0.7,1.2,2,1.9,3.7,1.2
        C283.1,158.4,314,194.9,308.5,222.5z"/>
    <path d="M291.5,209.5c-1.2-7.2-7.8-11.9-14.7-11.7c0,0,0,0,0,0c-0.2,0-0.4,0.1-0.6,0.1c-1.2,0.3-2.3,0.8-3.5,1.3
        c0,0-0.1,0.1-0.1,0.1c-6,3.7-9,8.8-7.8,16.4c1.2,7.7,8.7,11,15.7,10.1C287.7,224.8,292.6,216.3,291.5,209.5z M277.3,219
        c-3.5,0.1-5.4-3.2-5.7-6.3c-0.3-4.2,2.7-6.2,5.9-7.9C286.3,204.2,286.8,218.6,277.3,219z"/>
    <path d="M215.2,203.8c0,0.2,0,0.3,0,0.5c0,1.5,1.1,3.1,2.6,3.4c7.2,1.6,16.1-0.4,23.5-0.3c9.5,0.2,18.8,1.6,28.3,2.8
        c-0.8-2-1.7-4.1-2.5-6.1c-0.2,0.2-0.3,0.3-0.5,0.5c-3.2,3.3,1.8,8.3,5,5c0.2-0.2,0.3-0.3,0.5-0.5c2.4-2.4,0.4-5.7-2.5-6.1
        c-8.6-1.1-17.1-2.2-25.8-2.7c-4.1-0.2-8.2-0.2-12.4-0.1c-3.5,0.1-8.3,1.2-11.8,0.5c0.9,1.1,1.7,2.3,2.6,3.4c0-0.2,0-0.3,0-0.5
        C222.3,199.2,215.2,199.2,215.2,203.8L215.2,203.8z"/>
    <path d="M276.1,219.3c-3.8-2.7-7.3,3.5-3.6,6.2c-1.9-1.4-1.1-1.7-1.2,0.7c0,1.4-0.1,2.8-0.2,4.2c-0.2,2.4-0.6,4.7-1.2,7.1
        c-1.9,7.5-9.7,12.9-16.6,16.4c0,0-0.1,0-0.1,0c-0.4,0.3-0.9,0.4-1.4,0.3c-0.2,0.1-0.5,0-0.7,0c-0.2,0.1-0.3,0.2-0.5,0.3
        c-1.6,1.1-2.1,2.1-2.2,4c-0.1,1.1,0.3,1.9,0.9,2.5c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.3,0,0.5,0.1c0.3,0,0.5,0.1,0.8,0.1
        c0.8,0.1,1.5,0.1,2.3,0.2c0,0,0,0,0,0c0.2,0,0.3,0,0.5,0.1c0,0,0,0,0,0c10.4-4.6,20.6-12.4,23.5-24
        C278.4,232.9,280.7,222.5,276.1,219.3z"/>
    <path d="M280.4,281.2c-1.8,0-3.6,0.1-5.3,1c-0.7,0.4-1.4,0.8-2.1,1.2c-4.3,4.6-9.3,9-13.8,13.2c-6.5,6.1-16,8.1-24.6,8.8
        c-9.1,0.8-17.9,0-26.7-2.4c-8-2.2-14.3-8-22.3-10.1c-6.6-1.7-13.2,3-18.1,6.6c-6.2,4.5-12.3,7.9-17.2,13.9
        c-8.7,10.6-2,21.6,10.8,22.9c7,0.7,13-4.3,18.4-7.8c4.1-2.7,8.5,0.7,12.3,2c4.5,1.5,9,3.1,13.7,4.1c16.3,3.3,35.7,2.1,51.3-3.7
        c13.4-5,24.2-17.9,29.5-30.8C289.6,292.6,289.6,282.3,280.4,281.2z M279.6,298.2c-2.3,6.6-7.1,12.1-11.9,17
        c-10.8,10.9-25.5,13-40.1,13.8c-14.1,0.7-26.8-2.4-39.7-7.7c-12.2-5.1-21.3,14.7-32.9,5.7c-8.9-6.9,21.1-26.3,27.6-27.1
        c5.5-0.7,11.8,5.8,16.7,7.7c6.4,2.5,12.8,4,19.5,4.9c10.8,1.4,23.9,0.5,34-3.8c4.9-2.1,9.1-4.9,13-8.5c4.1-3.8,8.6-7.9,12.6-12
        c0.1,0,0.1,0,0.2,0C283.7,288.1,280.5,295.8,279.6,298.2z"/>
    <path d="M193.8,300.8c-2,4.1-3.1,7.9-4.1,12.3c-0.7,3.3-2.2,6.3-3.1,9.4c-0.5,0.6-0.9,1.3-0.9,2.3v1.4c0,0.7,0.2,1.4,0.5,1.9
        c0,0,0.1,0,0.1,0.1c1,0.7,2.2,1,3.2,1.5c1.5-0.2,3-1.4,3.3-3.4c0.4-3.2,2.4-6.6,3.3-9.7c1.3-4.3,1.8-8.1,3.8-12.2
        C202,300.3,195.8,296.7,193.8,300.8z"/>
    <path d="M257.9,292.7c0,0.2,0,0.3,0,0.5c0,4.6,7.1,4.6,7.1,0c0.1-22.2,26.8-31.3,37.9-10.4c2.1,4,3.5,9.7,2.8,14.1
        c-0.7,4.5-5.6,7.3-9.2,9.2c-2.4,1.3-17.5,7.5-19.7,5c-3-3.5-8,1.6-5,5c5.7,6.6,22.4-1.1,28.3-3.9c13-6.1,15.5-18.7,9.8-31.2
        c-5.7-12.5-17.4-19.8-31.1-16.8c-13.7,3-20.8,16-20.9,29c2.4,0,4.8,0,7.1,0c0-0.2,0-0.3,0-0.5C265.1,288.1,257.9,288.1,257.9,292.7
        L257.9,292.7z"/>
    <path d="M268.1,256.6c-0.9,2.7-7.9,6.9-10,8.8c-3.2,2.9-4.6,6.2-5.1,10.4c-0.6,4.9,1.5,10.3,2.5,15c0.7,3.6,0.7,7.2,3.1,10.2
        c2.9,3.6,7.9-1.5,5-5c-1.1-1.4-1-5.3-1.3-7c-0.8-5.3-4.4-12.2-0.5-17.1c3.8-4.7,11.2-7.3,13.1-13.3
        C276.3,254.1,269.4,252.2,268.1,256.6L268.1,256.6z"/>
    <path d="M297.5,245.2c1.5,4.3,2.1,8.5,4.2,12.8c2.9,6,5.7,12.1,8.6,18.1c5.8,11.9,10.8,24.2,16.8,36c6.1,11.9,13.2,23.5,20.4,34.8
        c3.6,5.7,7.4,11.4,11.2,17.1c2.7,4,5.8,9.3,9.5,12.5c-0.3-1.2-0.6-2.3-0.9-3.5c0.4-0.6,0.8-1.1,1.2-1.7c-4,2.3-0.4,8.4,3.6,6.2
        c1.1-0.6,1.8-1.4,2.1-2.6c0.3-1.2,0-2.6-0.9-3.5c-3.9-3.4-6.8-8.8-9.7-13.1c-3.4-4.9-6.6-10-9.8-15c-6.8-10.5-13.1-21.5-19.2-32.4
        c-6-10.8-10.2-22.6-15.8-33.7c-2.7-5.4-5.3-10.8-7.9-16.3c-1.3-2.8-2.6-5.6-4-8.4c-1.5-3.1-1.5-6-2.5-9.1
        C302.9,238.9,296,240.8,297.5,245.2L297.5,245.2z"/>
    <path d="M368.4,364.3c-0.9-0.3-1.7-1-2-1.8c-3.9,1-7.2,3.4-10.4,5.7c-5,3.6-10.2,5.9-15.9,8.1c-10.7,4.1-21.5,7.8-32.3,11.9
        c-5.9,2.3-12-9.8-14.3-13.5c-3.1-4.8-6.3-9.6-9.2-14.5c-3.1-5.1-5.8-10.3-8.4-15.7c-2.6-5.2-6.8-11.5-7.4-17.4c0,0,0,0,0,0
        c-0.1-0.4-0.2-0.7-0.2-1.1c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.4,0-1,0.3-1.3c-0.2-0.5-0.5-1-1-1.3c-2.4-2.7-6.9-0.3-6.3,2.9
        c0.1,5,2.1,9.3,4.3,13.7c3.3,6.6,6.4,13.3,10,19.8c6.4,11.6,13.8,25,23.7,33.9c3.7,3.4,8,2.6,12.3,1c7-2.7,14.1-4.8,21.1-7.6
        c6.5-2.6,13.1-4.9,19.5-7.7c5.8-2.6,10.4-8.3,16.7-9.8c2.7-0.6,3.1-3.3,2-5.2C370.1,364.4,369.3,364.6,368.4,364.3z"/>
    <path d="M399.7,321.9c-3.9-10.9-7.6-22.1-12.6-32.6c-8.2-17.1-18.3-36.2-31.8-49.7c-5.5-5.5-12.4-8.9-20.3-7.7
        c-10.1,1.5-19.5,9-29.6,9.6c-2.1-0.9-5.1,0.2-5.1,3.1v0.5c0,2,1.6,3.5,3.6,3.6c6.3,0.1,12.5-2.7,18.2-5.1c4.2-1.8,8.3-3.4,12.8-4.5
        c6.3-1.5,10.9,1.6,15.4,5.5c12.1,10.9,19.6,27.9,27.4,41.9c8.7,15.6,13.1,33.2,20,49.5c1.7,4.1,3.7,8.6,0.1,12.5
        c-2.3,2.5-7.2,4.9-9.9,6.9c-5,3.7-10.2,5.8-15.7,7.9c-0.8,0.5-1.7,0.9-2.5,1c-1.5,0.6-2.9,1.1-4.4,1.7c-4.2,1.7-2.4,8.6,1.9,6.9
        c8.6-3.5,16.5-5.9,24.3-11.4c5.4-3.8,14.6-7.9,15.4-15.4C407.5,338.1,402.3,329.4,399.7,321.9z"/>
    <path d="M280.3,318.7c2.3,4,3.7,8.8,5.7,13c2.4,5.2,5.6,9.9,9.1,14.3c0.2-1.9,0.5-3.7,0.7-5.6c-5.7,3-10.8,6.9-16.1,10.4
        c-3.8,2.5-0.3,8.7,3.6,6.2c5.4-3.5,10.5-7.4,16.1-10.4c2.2-1.2,2.1-3.9,0.7-5.6c-6.4-8-8.7-17.3-13.7-25.9
        C284.1,311.1,278,314.7,280.3,318.7L280.3,318.7z"/>
    <path d="M413.3,478.5c0-0.6-0.1-1.3-0.4-1.9c-0.1-0.1-0.1-0.3-0.2-0.4c-0.8-2-2.6-2.2-4.1-1.5c-1.1-0.5-4.6-1.6-4.7-1.6
        c-3-0.7-5.7-1.8-8.5-3c-5.5-2.5-10.8-5.4-16.2-8c-10.6-5-19.4-10.6-27.7-19c-7.6-7.8-13.7-17.5-20.8-25.8c-3.3-4-6.8-7.9-10.2-11.8
        c-2.9-3.3-9.5-8.5-10.5-12.9c-1.1-4.5-8-2.6-6.9,1.9c0.8,3.5,3.1,5.8,5.7,8.2c4.9,4.7,9.1,10.5,13.5,15.7
        c8.9,10.3,16.4,21.8,25.9,31.6c9.3,9.5,20.3,14.9,32.1,20.7c5.7,2.8,11.2,5.6,17.1,8.1c2.9,1.2,10.4,4.3,13.5,3.3
        c0.3-0.1,0.6-0.3,0.9-0.4C412,480.2,412.6,479.2,413.3,478.5z"/>
    <path d="M420.9,456.7c-5.1-3.5-10.1-7-15.1-10.6c-0.6-0.4-1.1-0.9-1.7-1.3c-0.2-0.1-0.4-0.2-0.6-0.4c-10.2-7.9-19.3-18-26.1-29
        c-7.9-12.8-11-30.5-12.2-45.3c-0.4-4.5-7.5-4.6-7.1,0c1.1,12.6,3.5,26.4,8,38.2c4.1,10.9,11.4,20.3,19.3,28.8
        c4,4.3,8.2,8.6,12.9,12.1c4.5,3.4,9.4,8.3,14.4,10.9c9.7,4.9-5,12-7.7,17.3c-0.7,1.4-0.7,3.2,0.6,4.3c0.1,0.1,0.1,0.1,0.2,0.2
        c2.4,2.1,5.6,0.3,6.1-2c1.3-1.7,3.5-3,4.9-4.5c3.1-3.1,5.8-6.7,7-11C424.7,461.4,423.4,458.4,420.9,456.7z"/>
    <path d="M367.9,373.6c0.1,0,0.2,0,0.4,0c4.6,0,4.6-7.1,0-7.1c-0.1,0-0.2,0-0.4,0c-2.6,0-4.6,3.1-3.1,5.4
        c9.2,14.1,19.9,27.6,30.7,40.5c4.8,5.7,10.2,10.6,16.3,14.9c7.1,4.9,11.6,9,20.2,10.9c-0.9-1.1-1.7-2.3-2.6-3.4
        c0,1.8,0.7,2.8,2.2,3.8c-0.6-1-1.2-2.1-1.8-3.1c0,0.2,0,0.4,0.1,0.7c0.4,4.5,7.5,4.6,7.1,0c-0.2-1.9-0.7-2.9-2.2-4
        c0.6,1,1.2,2.1,1.8,3.1c0-0.2,0-0.3,0-0.5c0-1.5-1.1-3.1-2.6-3.4c-13.6-3.1-26.1-15.3-34.9-25.8c-9.9-11.8-19.6-24.3-28-37.2
        C370,370.1,368.9,371.9,367.9,373.6c0.1,0,0.2,0,0.4,0c4.6,0,4.6-7.1,0-7.1c-0.1,0-0.2,0-0.4,0
        C363.3,366.5,363.3,373.6,367.9,373.6L367.9,373.6z"/>
    <path d="M429.1,437.5c-0.3,1.5-0.9,2.8-1.2,4.3c-0.2,1.2-1.2,3-0.3,3.1c-1.6,0-3.2,0.1-4.8,0.2c-4.6,0.4-9.9,1-14.6,0.1
        c-1,0-2,0-3-0.2c-0.2,0-0.5-0.1-0.7-0.1c-1.4,0.6-2.4,1.9-2,3.8c0,0.8,0.3,1.6,1,2.2l0.1,0.1c0.7,0.7,1.4,1,2.2,1
        c7.1,1.7,14.6-0.1,21.8,0.1c6.5,0.2,7.3-7.8,8.4-12.6C436.9,435,430,433,429.1,437.5z"/>
    <path d="M430.6,470c-1.8-3-3.4-7.8-6.7-9.4c-4.1-2-7.7,4.2-3.6,6.2c1.2,0.6,2.4,4,3.1,5c2.1,3.2-1,8-2.2,11.1
        c-2.6,6.8-6,13.2-11.6,18.1c-1.2,1-11.8,7-9.4,1.8c1.2-2.7,2.9-5.1,4.2-7.8c2.3-4.7,5.7-8.9,7.4-13.8c0-0.2,0.1-0.3,0.1-0.5
        c0.1-0.3,0.2-0.6,0.3-1c1.1-4.5-5.8-6.4-6.9-1.9c-1.4,5.5-5.7,10.6-8.2,15.8c-1.5,3.1-4.8,6.6-4.7,10.3c0.2,10,12.3,8.5,18.4,5.1
        c9.5-5.3,14.2-16.7,17.9-26.2C430.4,478.6,433.1,474.3,430.6,470z"/>
    <path d="M426.2,451.9c-0.6-1.4-0.7-1.5-0.4-0.1c0.5,1.6,1,3.2,1.5,4.8c0.8,2.7,1.8,5.4,3,8c1.6,3.3,3.2,6.5,5.1,9.7
        c3.1,5,8.3,12.6,15.2,10.7c9.5-2.6,7.2-17.7,5.1-24.2c-1.7-5.4-4.2-10.7-6.5-15.9c-2.8-6.4-6.1-7.7-12-10.4
        c-4.1-1.9-7.8,4.2-3.6,6.2c3.4,1.6,6.3,2.3,8.4,5.8c2.2,3.6,3.3,7.9,5,11.7c1.6,3.6,2.9,7.5,3.5,11.4c0.4,3.1-0.4,10.6-4.4,6.9
        c-3.4-3.2-5.9-7.8-8-12c-1.6-3.2-2.8-6.5-4-9.8c-0.9-2.6-0.7-5.8-2.9-7.7C427.8,443.8,422.8,448.8,426.2,451.9L426.2,451.9z"/>
    <path d="M173.6,395.2c-0.7-1.2-2.1-2-3.5-2c-5.5-0.5-12.4,3.7-16.8,6.1c-7.6,4.2-14.2,9.6-20.7,15.2c-8.4,7.2-16.6,11.1-26.8,4.5
        c-7.2-4.6-10.8-13.7-10.8-22c0-4.6-7.1-4.6-7.1,0c0,15.8,10.9,30.9,27.1,32.8c10,1.1,17.6-5.9,24.5-11.9c4.8-4.1,9.6-7.9,15-11.2
        c2.3-1.4,4.7-2.7,7.1-3.9c0.2-0.1,6.6-2.9,7.6-2.7c1.6,0.4,3.1-0.2,4-1.6c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.3,0.3-0.5
        C173.3,397,173.3,396.1,173.6,395.2z"/>
    <path d="M197.9,374.6c-11.3,0.3-22.9,3.8-29.2,13.7c-5.7,8.9-1.8,20.6,9.3,20.8c10.3,0.2,23.3-7.2,29-15.4c2.4-3.5,6-9.1,3.4-13.5
        c-2.6-4.5-9.2-5.8-13.9-6.1c-1.9-0.2-3.6,1.7-3.6,3.6c0,0.2,0,0.3,0,0.5c0,4.6,7.1,4.6,7.1,0c0-0.2,0-0.3,0-0.5
        c-1.2,1.2-2.4,2.4-3.6,3.6c5.2,0.4,8.7,1.8,5.5,7.2c-1.7,3-4.6,5.3-7.3,7.3c-4.3,3.1-9.4,4.8-14.4,6c-4.9,1.2-7.8-2.1-6.6-7.1
        c0.9-3.6,3.9-6.3,6.8-8.4c5-3.5,11.5-4.4,17.5-4.6C202.4,381.6,202.5,374.5,197.9,374.6L197.9,374.6z"/>
    <path d="M187.5,407.7c0.9-0.5,1.9-0.9,2.9-1.2c0.1,0,0.2,0,0.3,0c0.5-0.6,0.8-1.3,0.8-2.2v-1c0-1-0.3-1.7-0.8-2.3
        c-1.8-5.8-3.8-11.6-3.9-17.7c-0.1-4.6-7.3-4.6-7.1,0c0.2,7.6,2.8,14.6,4.9,21.8C184.9,406.8,186.2,407.6,187.5,407.7z"/>
    <path d="M524.8,338.2c0.9-0.1,1.8-0.2,2.7-0.2c0.4-2.2,1-4.4,1.1-6.6c-0.4-0.1-0.8-0.3-1.3-0.3c-26.5-1.9-52.9,1-79.2,4
        c-2.1-9.9-2.4-20.6-3.2-30.7c-0.9-12.6-0.9-25.3-1.1-38c0-1.1-0.1-2.3-0.2-3.8c11.7-3.1,26-9,26.6-20.8c0.4-8.2-5.7-15.8-13.5-17.9
        c-12.4-3.2-18.4,9.3-19.8,19.2c-0.7,4.6-0.7,9.3-0.5,14c-3.2,0.6-6.3,1.1-9.1,1.5c-8.9,1.3-18,1-26.9,0.3
        c-10.5-0.9-23.3-2.3-33.2-6.1c-4.3-1.7-6.1,5.2-1.9,6.9c5.8,2.3,12.8,3,19,4.2c7,1.3,14.1,2.1,21.3,2.6c9.8,0.6,19.6,0,29.3-1.9
        c0.6-0.1,1.2-0.2,1.9-0.4c0,0.7,0,1.4,0.1,2.1c0.2,9.1,0.1,18.1,0.5,27.2c0.6,15.1,0.9,31.6,4.7,46.3c0.4,1.4,1.9,2.8,3.4,2.6
        c26.3-2.9,52.8-6.1,79.3-4.4C524.7,338.1,524.7,338.1,524.8,338.2z M454,230.8c8-0.3,11.3,10.4,6.6,15.5
        c-4.2,4.6-10.6,7.4-17.3,9.3C442.9,244.7,443.7,231.3,454,230.8z"/>
    <path d="M275.6,162.4c-17.7-2.7-34.1,3-47.4,13.7c-0.2,0.2-0.4,0.4-0.7,0.6c-1.3,1.3-2.6,2.6-3.9,4c-0.7,1-1.5,1.9-2.3,2.8
        c-0.2,0.4-0.4,0.9-0.7,1.3c-0.7,1.3-1.4,2.7-2,4c1.1,1.9,3.6,3,5.5,0.9c12.6-13.9,30.3-23.4,49.6-20.5
        C278.2,170,280.2,163.1,275.6,162.4z"/>
</g>
<g id="Layer_3">
    <polygon points="76.2,400.4 18.4,310.3 36,292.9 93.6,377.9 170.5,339.9 175.5,364.3 105.5,398.3 101.8,390.6 165.8,359.5 
        164.4,352.5 90.7,388.8 34.7,306.2 29.3,311.5 83.4,395.8 	"/>
    <g>
        <path d="M507.2,309.6h-17.3l-3.5,10.2H479l16.6-45.7h5.8l16.6,45.7h-7.3L507.2,309.6z M505.2,303.5l-6.6-19.9h-0.1l-6.6,19.9
            H505.2z"/>
        <path d="M520.9,274.1h6.9v29.4c0,3.1,0.9,5.5,2.7,7.1c1.7,1.7,3.9,2.6,6.6,2.6c2.7,0,4.9-0.9,6.7-2.6c1.7-1.6,2.6-4,2.6-7.1v-29.4
            h6.9v30.2c-0.1,4.8-1.6,8.6-4.7,11.4c-3,2.9-6.9,4.4-11.6,4.4c-4.6,0-8.4-1.5-11.5-4.4c-3.1-2.8-4.7-6.6-4.8-11.4V274.1z"/>
        <path d="M562.1,274.1h6.5l20.7,32.4h0.1v-32.4h6.9v45.7h-6.5l-20.6-32.4H569v32.4h-6.9V274.1z"/>
        <path d="M636.8,307c-1,4.2-2.9,7.5-5.7,9.8c-2.9,2.3-6.3,3.4-10.3,3.4c-3.6-0.1-6.6-1-9-2.6c-2.4-1.6-4.2-3.5-5.3-5.6
            c-0.3-0.7-0.6-1.3-0.9-2c-0.2-0.6-0.5-1.5-0.6-2.4c-0.3-1.8-0.5-5.4-0.5-10.6c0-5.3,0.2-8.9,0.5-10.6c0.3-1.8,0.8-3.2,1.5-4.4
            c1.1-2.1,2.9-4,5.3-5.6c2.4-1.7,5.4-2.5,9-2.6c4.4,0,8,1.3,10.8,3.9c2.8,2.6,4.5,5.8,5.3,9.6h-7.3c-0.6-1.8-1.6-3.4-3.1-4.7
            c-1.5-1.2-3.4-1.9-5.6-1.9c-1.6,0-3,0.4-4.2,1c-1.2,0.6-2.2,1.4-2.9,2.3c-0.9,1-1.5,2.2-1.8,3.8c-0.3,1.6-0.5,4.7-0.5,9.2
            c0,4.5,0.2,7.5,0.5,9.1c0.3,1.6,0.9,2.9,1.8,3.9c0.7,0.9,1.7,1.7,2.9,2.3c1.2,0.7,2.6,1,4.2,1c3.9,0,6.8-2.1,8.7-6.2H636.8z"/>
        <path d="M666.6,299.9h-18.1v19.9h-6.9v-45.7h6.9v19.6h18.1v-19.6h6.9v45.7h-6.9V299.9z"/>
        <path d="M683.2,274.1h16.5c1.8,0,3.5,0.2,5.2,0.5c1.7,0.4,3.4,1.3,5.1,2.7c1.4,1,2.6,2.5,3.5,4.4c0.9,1.8,1.4,4,1.4,6.5
            c0,3.4-1.2,6.5-3.6,9.2c-2.4,2.9-6,4.4-10.7,4.5h-10.5v17.8h-6.9V274.1z M690.1,295.4h10.2c2.6,0,4.5-0.8,5.8-2.4
            c1.2-1.5,1.8-3.2,1.8-5c0-1.5-0.3-2.8-0.8-3.9c-0.5-0.9-1.3-1.7-2.2-2.2c-1.4-0.9-3.1-1.3-4.9-1.3h-10V295.4z"/>
        <path d="M739.8,309.6h-17.3l-3.5,10.2h-7.3l16.6-45.7h5.8l16.6,45.7h-7.3L739.8,309.6z M737.9,303.5l-6.6-19.9h-0.1l-6.6,19.9
            H737.9z"/>
        <path d="M755.5,274.1h16c6.2,0,10.8,2.6,13.9,7.7c1.1,1.7,1.8,3.5,2.1,5.4c0.2,1.9,0.3,5.1,0.3,9.7c0,4.9-0.2,8.3-0.5,10.3
            c-0.2,1-0.4,1.9-0.8,2.6c-0.4,0.8-0.8,1.5-1.3,2.3c-1.3,2.1-3.2,3.9-5.6,5.3c-2.4,1.5-5.3,2.3-8.7,2.3h-15.5V274.1z M762.5,313.3
            h8.3c3.9,0,6.7-1.3,8.5-4c0.8-1,1.3-2.2,1.5-3.7c0.2-1.5,0.3-4.3,0.3-8.3c0-4-0.1-6.8-0.3-8.5c-0.3-1.7-0.8-3.1-1.8-4.2
            c-1.9-2.7-4.6-3.9-8.1-3.9h-8.3V313.3z"/>
    </g>
</g>
</svg>
)

export default LaunchpadLogo;